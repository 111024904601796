import WebApp from "@twa-dev/sdk";
import { createContext, useContext, useEffect, useState } from "react";

const StorageKey = "features-color-theme";
const supportedThemes = {
  light: "light",
  dark: "dark",
};

type Themes = keyof typeof supportedThemes;

const ThemeContext = createContext<
  | {
    theme: Themes;
    setTheme: (theme: Themes) => void;
    supportedThemes: { [key: string]: string };
    clickerTheme: Themes | null;
    setClickerTheme: (theme: Themes) => void;
  }
  | undefined
>(undefined);

export function useTheme() {
  const context = useContext(ThemeContext);

  if (!context) {
    throw new Error(
      'You can use "useTheme" hook only within a <ThemeProvider> component.'
    );
  }

  return context;
}

export function ThemeProvider(props: { children: React.ReactNode }) {
  const [theme, setTheme] = useState<Themes>(getTheme());
  // const [theme, setTheme] = useState<Themes>('dark');
  const [clickerTheme, setClickerTheme] = useState<Themes>(getClickerTheme());
  // const [clickerTheme, setClickerTheme] = useState<Themes>('dark');

  function getTheme() {
    const theme = WebApp
      ? WebApp.colorScheme
      : window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches
        ? "dark"
        : "light";

    localStorage.setItem(StorageKey, theme);

    return theme as Themes;
  }

  function getClickerTheme() {
    const theme = WebApp
      ? WebApp.colorScheme
      : window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches
        ? "dark"
        : "light";

    return theme as Themes;
  }

  useEffect(() => {
    document.documentElement.setAttribute("data-theme", theme);
  }, [theme]);


  useEffect(() => {
    if (clickerTheme == null) {
      document.body.classList.remove('body-dark-theme');
      document.body.classList.remove('body-light-theme');
    }
    if (clickerTheme == 'dark') document.body.classList.add('body-dark-theme');
    if (clickerTheme == 'light') document.body.classList.add('body-light-theme');

  }, [clickerTheme]);

  return (
    <ThemeContext.Provider
      value={{
        theme,
        setTheme,
        supportedThemes,
        clickerTheme,
        setClickerTheme
      }}
    >
      {props.children}
    </ThemeContext.Provider>
  );
}
