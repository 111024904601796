import { useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { Card } from '../../../Components/Predict/card/card'
import style from './pixieFamiliesStyle.module.scss'
import { HorizontalNavigation } from '../../../Components/Predict/navigation/horizontalNavigation';

export function PixieFamiliesPage() {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const currentLocation = location.pathname;
        if (currentLocation == '/predict/pixie-families' || currentLocation == "/predict/pixie-families/") {
            navigate("/predict/pixie-families/my-family");
        }
    }, [])

    return (
        <div className={style.pixieFamiliesContainer}>
            <HorizontalNavigation
                navItems={[
                    { title: "My Family", to: "/predict/pixie-families/my-family" },
                    { title: "Leaderboard", to: "/predict/pixie-families/leaderboard" },
                    { title: "Contests", to: "/predict/pixie-families/contests" }
                ]}
            />

            <Card className={style.pixieFamiliesContainer_welcomeBox}>
                <div className={style.pixieFamiliesContainer_welcomeBox_title}>
                    Welcome to Pixie Families
                </div>
                <div className={style.pixieFamiliesContainer_welcomeBox_subTitle}>
                    Here you can view your family details, see the leaderboard, and participate in contests.
                </div>
            </Card>

            <div className={style.pixieFamiliesContainer_content}>
                <Outlet />
            </div>
        </div>
    )
}
