export class GameHistory {
    constructor(
        public locked_at: Date,
        public price_last: number,
        public price_locked: number,
    ) { }
}

export function mapToGameHistory(data: any) {
    return new GameHistory(
        new Date(Date.parse(data.locked_at)),
        data.price_last ?? 0,
        data.price_locked ?? 0
    )
}