import { Improvement, mapToImprovement } from "./improvement";

export class ImprovementsProvider {
    public static async getImprovements(): Promise<Improvement[]> {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/clicker/improvements/get`)
            .then(response => response.json())
            .then(response => {
                return response;
            });

        return (response.improvements as any[]).map(mapToImprovement);
    }

    public static async setImprovements(improvement_id: any, date_now_timestamp: number, token: string): Promise<any> {
        let bodyData = {
            "improvement_id": improvement_id,
            "timestamp": date_now_timestamp
        };

        const response = await fetch(`${process.env.REACT_APP_API_URL}/v3/improvements/set`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'auth-api-token': token!
            },
            body: JSON.stringify(bodyData)
        }).then(response => response.json())
            .then(response => { return response })

        return response;
    }
}