import React, { useEffect, useState } from 'react'
import style from './marketplaceStyle.module.scss'
import { nft } from '../profile/nfts';
import skin1 from '../../../Resources/Predict/images/skinsUpgrade/1.png'
import skin2 from '../../../Resources/Predict/images/skinsUpgrade/2.png'
import skin3 from '../../../Resources/Predict/images/skinsUpgrade/3.png'
import skin4 from '../../../Resources/Predict/images/skinsUpgrade/4.png'
import ethereumIcon from '../../../Resources/Predict/icons/Ethereum.svg'

export function MarketplaceSkinsUpgradeBlock() {
    const [skins, setSkins] = useState<nft[]>([]);

    useEffect(() => {
        loadNfts();
    }, [])

    function loadNfts() {
        setSkins([
            { name: "Abstract Pink", number: "abstract #2538", price: 0.906, code: 1800, photo: skin1 },
            { name: "Abstract Pink", number: "abstract #2538", price: 0.906, code: 1800, photo: skin2 },
            { name: "Abstract Pink", number: "abstract #2538", price: 0.906, code: 1800, photo: skin3 },
            { name: "Abstract Pink", number: "abstract #2538", price: 0.906, code: 1800, photo: skin4 }
        ])
    }

    return (
        <div className={style.marketplaceSkinsUpgradeBlock}>
            {
                skins.map((nft, index) =>
                    <div key={index} className={style.marketplaceSkinsUpgradeBlock_nftCard}>
                        <img src={nft.photo} alt={nft.name} />
                        <div className={style.marketplaceSkinsUpgradeBlock_nftCard_title}>
                            {nft.name}
                        </div>
                        <div className={style.marketplaceSkinsUpgradeBlock_nftCard_subTitle}>
                            {nft.number}
                        </div>
                        <div className={style.marketplaceSkinsUpgradeBlock_nftCard_priceBlock}>
                            <div className={style.marketplaceSkinsUpgradeBlock_nftCard_priceBlock_ethereum}>
                                <img src={ethereumIcon} alt="ethereumIcon" />
                                <span>{nft.price}</span>
                            </div>
                            <div className={style.marketplaceSkinsUpgradeBlock_nftCard_priceBlock_code} >
                                {nft.code}
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    )
}
