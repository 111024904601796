import React, { useCallback, useContext, useEffect, useState } from 'react'
import './style.scss'
import GameContext from '../../../Context/GameContext';
import { coins_images } from '../../../constants';

type DraggableButtonProps = {
    onClick: () => void;
};

export default function DraggableButton(props: DraggableButtonProps) {
    const { coinImageId } = useContext(GameContext);

    return (
        <div
            className='main'
            onClick={props.onClick}
        >
            <div className='main-sub'>
                <div className='main-sub-coin'>
                    <div
                        style={{ boxShadow: '0px 0px 110px 0px #AD5519' }}>
                    </div>
                </div>
            </div>
        </div>
    );
}
