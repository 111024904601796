import React from 'react'
import { NavLink } from 'react-router-dom'
import style from './horizontalNavigationStyle.module.scss'

interface NavItem {
    to: string;
    title: string;
}

interface IProps {
    navItems: NavItem[];
}

export function HorizontalNavigation(props: IProps) {
    return (
        <div className={style.navigationBlock}>
            {
                props.navItems.map((item, index) =>
                    <NavLink
                        key={index}
                        to={item.to}
                        className={({ isActive }) =>
                            isActive ? `${style.navigationBlock_navItem} ${style.active}` : style.navigationBlock_navItem
                        }
                    >
                        <div>{item.title}</div>
                    </NavLink>
                )
            }
        </div>
    )
}
