import { mapToReferral, Referral } from "./referral";

export class ReferralsProvider {
    public static async getReferrals(userId: number): Promise<Referral[]> {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/clicker/referrals/get/${userId}`)
            // fetch(`http://game.pixie.loc/api/v2/clicker/referrals/get/${userId}`)
            .then(response => response.json())
            .then(response => { return response })

        return (response.referrals as any[]).map(mapToReferral);
    }

    public static async getCoinsFromReferrals(token: string, date_now_timestamp: number): Promise<any> {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/v3/referrals/get/coins`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'auth-api-token': token!
            },
            body: JSON.stringify({ "timestamp": date_now_timestamp })
        }).then(response => response.json())
            .then(response => { return response })

        return response;
    }
}