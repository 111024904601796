import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import GameContext from '../../../../Context/GameContext';
import { InvoiceProvider } from '../../../../Domain/Invoice/invoiceProvider';
import PLoader from '../../../../Components/Predict/loader/Loader';
import { InvoicePaymentInfo } from '../../../../Domain/Invoice/invoicePaymentInfo';
import './style.scss'
import MiniLoader from '../../../../Components/Predict/loader/MiniLoader';
import UsdtIcon from '../../../../Resources/Predict/icons/USDT.svg'
import CopyIcon from '../../../../Resources/Predict/icons/copy.svg'
import BackIcon from '../../../../Resources/Predict/icons/back.svg';

import toast, { Toaster } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

export default function InvoicePaymentPage() {
    const { token } = useContext(GameContext);

    const [invoicePaymentInfo, setInvoicePaymentInfo] = useState<InvoicePaymentInfo | null>(null);

    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const { id } = useParams();

    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        if (id == null) return;

        loadInvoicePaymentInfo();

    }, [id])

    async function loadInvoicePaymentInfo() {
        if (id == null) throw new Error("Invoice id is null");
        setIsLoaded(false);

        const paymentInfo = await InvoiceProvider.get(id, token!);
        setInvoicePaymentInfo(paymentInfo)
        setIsLoaded(true);
    }

    async function copyWalletAddress() {
        if (invoicePaymentInfo == null) return;

        await navigator.clipboard.writeText(invoicePaymentInfo.api_address);
        toast.success(t("Copied"))
    }

    async function copyUuid() {
        if (invoicePaymentInfo == null) return;

        await navigator.clipboard.writeText(invoicePaymentInfo.uuid);
        toast.success(t("Copied"))
    }

    if (!isLoaded) return <PLoader />

    return (
        <div className='invoice-payment-page-container'>
            <div className="invoice-payment-page-container-header">
                <div className="invoice-payment-page-container-header-title">
                    <div className="invoice-payment-page-container-header-title-back" onClick={() => navigate(-1)}>
                        <img src={BackIcon} alt="BackIcon" /></div>
                    {t("Payment")}
                </div>
            </div>
            {
                invoicePaymentInfo != null &&
                <div className='invoice-payment-page-container-content'>
                    {!([1, 100, -1].includes(invoicePaymentInfo.status)) &&
                        <>
                            <h2>{t("QR-code for payment")}</h2>
                            <div className='invoice-payment-page-container-content-qr-code'>
                                {invoicePaymentInfo.api_qrcode_url
                                    ? <img src={invoicePaymentInfo.api_qrcode_url} alt="api_qrcode_url" />
                                    : <MiniLoader />
                                }
                            </div>
                        </>
                    }

                    <div className='invoice-payment-page-container-content-wallet' >
                        {
                            [1, 100].includes(invoicePaymentInfo.status) &&
                            <span className='invoice-payment-page-container-content-wallet-title'>{t(invoicePaymentInfo.status_text)} {invoicePaymentInfo.receivedf}<img src={UsdtIcon} alt="UsdtIcon" /></span>
                        }
                        {
                            invoicePaymentInfo.status == -1 &&
                            <span className='invoice-payment-page-container-content-wallet-title'>{t(invoicePaymentInfo.status_text)}</span>
                        }
                        {
                            !([1, 100, -1].includes(invoicePaymentInfo.status)) &&
                            <>
                                <span className='invoice-payment-page-container-content-wallet-address' onClick={copyWalletAddress}><span>{invoicePaymentInfo.api_address}</span> <img src={CopyIcon} alt="CopyIcon" /></span>
                                <span className='invoice-payment-page-container-content-wallet-subtitle'>{t("wallet address")}</span>
                            </>
                        }
                        <span className='invoice-payment-page-container-content-wallet-subtitle'>{t("USDT.TRC20 Network")}</span>
                    </div>
                </div>
            }
            <div><Toaster /></div>
            <div className='invoice-payment-page-container-uuid' onClick={copyUuid}>{invoicePaymentInfo && <span>uuid{invoicePaymentInfo.uuid}</span>}  <img src={CopyIcon} alt="CopyIcon" /></div>
        </div>
    )
}
