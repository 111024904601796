import { Nft } from "./nft";

export class NftBlock {
    constructor(
        public name: string,
        public nfts: Nft[],
    ) { }
}

export function mapToNftBlock(name: string, nfts: Nft[]) {
    return new NftBlock(
        name,
        nfts
    )
}