import { useContext, useEffect, useState } from 'react'
import Modal from '../../../../../Components/Predict/modal/modal'
import GameContext from '../../../../../Context/GameContext';
import { useTranslation } from 'react-i18next';
import { Card } from '../../../../../Components/Predict/card/card';
import CoinIcon from '../../../../../Resources/Predict/images/coin.svg'
import PartyIcon from '../../../../../Resources/Predict/icons/party.svg'
import style from './resultModalStyle.module.scss';
import { GameProcess } from '../../../../../Domain/Predict/gameProcess';

interface IProps {
    isOpen: boolean;
    onClose: () => void;
}

export function PredictionFillingGlassResultModal(props: IProps) {
    const { userId } = useContext(GameContext);
    const [shareText, setShareText] = useState<string>("");

    const { t, i18n } = useTranslation();

    // function isWinGame() {
    //     return props.player_price_win != null ? true : (props.coef_up == null || props.price_last == props.price_locked) ? null : false;
    // }

    const bets = [
        { nickname: 'Игрок1', amount: 500 },
        { nickname: 'Игрок2', amount: 350 },
        { nickname: 'Игрок3', amount: 200 },
        { nickname: 'Игрок4', amount: 150 },
        { nickname: 'Игрок5', amount: 100 },
    ];

    const totalAmount = bets.reduce((sum, bet) => sum + bet.amount, 0);


    return (
        <Modal isOpen={props.isOpen} onClose={props.onClose}>
            <Card className={style.resultModalContainer}>
                <div className={`${style.resultModalContainer_title} ${style.green}`}>
                    You Won!
                    <img src={PartyIcon} alt="PartyIcon" />
                </div>
                <div className={style.resultModalContainer_mainResult}>
                    <div className={style.resultModalContainer_mainResult_main}>
                        Total Prize Pool: {(+((totalAmount).toFixed(2))).toLocaleString('en')}
                    </div>
                    <div className={style.resultModalContainer_mainResult_main}>
                        Your bet: {(+((12).toFixed(2))).toLocaleString('en')}
                    </div>
                    <div className={style.resultModalContainer_mainResult_main}>
                        Сhance of winning: {(+((12).toFixed(2))).toLocaleString('en')}%
                    </div>

                    <div className={style.betsTable}>
                        {
                            bets.map((bet, index) => (
                                <div key={index} className={style.betRow}>
                                    <span className={style.nickname}>{bet.nickname}</span>
                                    <div className={style.amount}>
                                        <span>{bet.amount}</span>
                                        <img src={CoinIcon} alt="Coin Icon" className={style.coinIcon} />
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className={style.resultModalContainer_buttons_button} onClick={props.onClose}>
                    Ok
                </div>
            </Card>
        </Modal>
    )
}
