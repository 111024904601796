export class Referral {
    constructor(
        public referal_id: number,
        public avatar_url: string,
        public earn_received_level_1: number,
        public earn_received_level_2: number,
        public earn_received_level_3: number,
        public earn_received_level_4: number,
        public earn_received_level_5: number,
        public earn_received_level_6: number,
        public earn_received_level_7: number,
        public earn_received_level_8: number,
        public first_name: string | null,
        public last_name: string,
        public inviter_id: number,
        public level: number,
        public skin_id: number,
        public total_earn: number,
        public username: string,
    ) { }
}

export function mapToReferral(data: any) {
    return new Referral(
        data.referal_id,
        data.avatar_url,
        data.earn_received_level_1,
        data.earn_received_level_2,
        data.earn_received_level_3,
        data.earn_received_level_4,
        data.earn_received_level_5,
        data.earn_received_level_6,
        data.earn_received_level_7,
        data.earn_received_level_8,
        data.first_name,
        data.last_name,
        data.inviter_id,
        data.level,
        data.skin_id,
        data.total_earn,
        data.username,
    );
}