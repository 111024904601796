import { useContext, useEffect, useState, useTransition } from 'react'
import { InvoiceProvider } from '../../../../Domain/Invoice/invoiceProvider';
import { Invoice } from '../../../../Domain/Invoice/invoice';
import { Card } from '../../../../Components/Predict/card/card';
import CalendarIcon from '../../../../Resources/Predict/icons/calendar.svg';
import CalendarDarkIcon from '../../../../Resources/Predict/icons/calendar_dark.svg';
import CrossBalanceIcon from '../../../../Resources/Predict/icons/crossBalance.svg';
import CheckedBalanceIcon from '../../../../Resources/Predict/icons/CheckedBalance.svg';
import partialBalancerIcon from '../../../../Resources/Predict/icons/partialBalancer.svg';
import UsdtIcon from '../../../../Resources/Predict/icons/USDT.svg'
import WaitBalanceIcon from '../../../../Resources/Predict/icons/WaitBalance.svg';
import style from './usdtBalanceHistoryStyle.module.scss';
import { useTheme } from '../../../../Context/Predict/themeContext';
import GameContext from '../../../../Context/GameContext';
import PLoader from '../../../../Components/Predict/loader/Loader';
import CoinIcon from '../../../../Resources/Predict/icons/coin.svg';
import { useNavigate } from 'react-router-dom';
import Modal from '../../../../Components/Predict/modal/modal';
import { RoundedNumberInput } from '../../../../Components/Predict/inputs/roundedTextInput/roundedNumberInput';
import { useTranslation } from 'react-i18next';

export default function ProfileUsdtBalanceHistoryBlock() {
    const { token, balance_usdt } = useContext(GameContext);
    const [usdtBalanceHistory, setUsdtBalanceHistory] = useState<Invoice[]>([]);
    // const [isOpenCreateInvoiceModal, setIsOpenCreateInvoiceModal] = useState<boolean>(false);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const { theme } = useTheme();
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        loadInvoices();
    }, [])

    async function loadInvoices() {
        setIsLoaded(false);

        const invoices = await InvoiceProvider.getList(token!);
        setUsdtBalanceHistory(invoices)
        setIsLoaded(true);
    }

    function loadInvoicePaymentPage(invoice: Invoice) {
        if (!([0, 1].includes(invoice.status))) return;

        navigate(`/predict/profile/invoice/${invoice.uuid}`)
    }

    // function closeCreateModal(result: boolean, uuid: string | null) {
    //     setIsOpenCreateInvoiceModal(false);
    //     if (result) {
    //         loadInvoices();
    //         navigate(`/predict/profile/invoice/${uuid}`)
    //     }
    // }

    async function createDeposit() {
        const result = await InvoiceProvider.create(token!);
        loadInvoices();
        navigate(`/predict/profile/invoice/${result.uuid}`)
    }

    if (!isLoaded) return <PLoader />

    return (
        <div className={style.profileBalancaHistoryBlock}>
            <div className={style.profileBalancaHistoryHeader}>
                <div className={style.profileBalancaHistoryBalance}>{t("Balance")} {balance_usdt ?? 0} <img src={UsdtIcon} alt="UsdtIcon" /></div>
                <div className={style.profileBalancaHistoryDepositButton} onClick={createDeposit}>{t("Deposit")}</div>
            </div>
            {
                usdtBalanceHistory.map((item, index) =>
                    <Card key={index} className={style.profileBalancaHistoryBlock_transaction} onClick={() => loadInvoicePaymentPage(item)}>
                        <div className={style.profileBalancaHistoryBlock_transactionLeft}>
                            {
                                [-1, -2].includes(item.status) ? <img src={CrossBalanceIcon} alt="topUpIcon" />
                                    : [1, 100].includes(item.status)
                                        ? <img src={CheckedBalanceIcon} alt="CheckedBalanceIcon" />
                                        : item.status == 0
                                            ? <img src={WaitBalanceIcon} alt="topUpIcon" />
                                            : <img src={CrossBalanceIcon} alt="topUpIcon" />
                            }
                            <div className={style.profileBalancaHistoryBlock_transactionLeft_information}>
                                <div className={style.profileBalancaHistoryBlock_transactionLeft_information_date}>
                                    <img src={theme == 'light' ? CalendarIcon : CalendarDarkIcon} alt="calendarIcon" />
                                    <span>{item.created_at.toLocaleDateString()}</span>
                                </div>
                            </div>
                        </div>
                        <div className={style.profileBalancaHistoryBlock_transactionRight}>
                            {
                                item.receivedf && item.receivedf != 0 &&
                                <div className={style.profileBalancaHistoryBlock_transactionRight_amount}>
                                    <img src={UsdtIcon} alt="UsdtIcon" />
                                    <span>+{(+(+(item.receivedf)).toFixed(2)).toLocaleString("en")}</span>
                                </div>
                            }
                        </div>
                    </Card>
                )
            }

            {/* {
                isOpenCreateInvoiceModal &&
                <CreateInvoiceModal isOpen={isOpenCreateInvoiceModal} onClose={closeCreateModal} />
            } */}
        </div >
    )
}

interface ICreateInvoiceModalProps {
    isOpen: boolean;
    onClose: (result: boolean, uuid: string | null) => void;
}

// function CreateInvoiceModal(props: ICreateInvoiceModalProps) {
//     const { token } = useContext(GameContext);
//     const [deposit, setDeposit] = useState<number | null>(null);
//     const [isBetSending, setIsBetSending] = useState<boolean>(false);

//     const { t } = useTranslation();

//     async function createDeposit() {
//         if (deposit == 0 || deposit == null) return;

//         setIsBetSending(true);

//         const result = await InvoiceProvider.create(deposit, token!);

//         setIsBetSending(false);

//         props.onClose(true, result.uuid);
//     }

//     return (
//         <div className={style.overlay}>
//             <Card className={style.modalContainerCard}>
//                 {
//                     isBetSending
//                         ? <PLoader />
//                         : <>
//                             <div className={style.modalContainerCard_headerButton}>
//                                 <span>{t("Specify the amount of the deposit")}</span>
//                                 <div onClick={() => props.onClose(false, null)}>✕</div>
//                             </div>
//                             <RoundedNumberInput
//                                 value={deposit}
//                                 onChange={setDeposit}
//                                 color='light'
//                                 minValue={0}
//                                 placeholder={"0"}
//                                 autoFocus
//                                 style={{
//                                     padding: "8px 9px",
//                                     fontSize: "34px",
//                                     borderRadius: "6px",
//                                     textAlign: "center",
//                                 }}
//                             />

//                             <div className={style.modalContainerCard_depositButton} onClick={createDeposit}>{t("Deposit")}</div>
//                         </>
//                 }
//             </Card>
//         </div>
//     )
// }