export class Task {
    constructor(
        public id: number,
        public task_id: number,
        public name_ru: string,
        public name_en: string,
        public name_uk: string,
        public coins: number,
        public link: string | null,
        public group_id: number | null,
        public locale: string | null,
        public active: boolean,
        public friends_invite: number | null,
        public friends_invited: number | null,
        public type: number,
        public limit_users: number,
        public was_completed: boolean,
        public user_id: number,
        public webhook_done: boolean,
    ) { }
}

export function mapToTask(data: any) {
    return new Task(
        data.id,
        data.task_id,
        data.name_ru,
        data.name_en,
        data.name_uk,
        data.coins,
        data.link,
        data.group_id,
        data.locale,
        data.active,
        data.friends_invite,
        data.friends_invited,
        data.type,
        data.limit_users,
        data.was_completed,
        data.user_id,
        data.webhook_done,
    );
}