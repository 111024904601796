import { Coin, mapToCoin } from "./coint";

export class CoinsProvider {
    public static async getAll(userId: number): Promise<Coin[]> {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/clicker/coins/get/all/${userId}`)
            .then(response => response.json())
            .then(response => response);

        return (response.coins as any[]).map(mapToCoin);
    }

    public static async buy(coinId: any, date_now_timestamp: number, token: string): Promise<any> {
        let data = {
            "coin_id": coinId,
            "timestamp": date_now_timestamp
        };

        const response = await fetch(`${process.env.REACT_APP_API_URL}/v2/coins/buy`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'auth-api-token': token
            },
            body: JSON.stringify(data)
        }).then(response => response.json())
            .then(response => response)

        return response;
    }

    public static async set(coinId: any, date_now_timestamp: number, token: string): Promise<any> {
        let data = {
            "coin_id": coinId,
            "timestamp": date_now_timestamp
        };

        const response = await fetch(`${process.env.REACT_APP_API_URL}/v2/coins/set`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'auth-api-token': token
            },
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(response => response)

        return response;
    }
}