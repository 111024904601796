export class Nft {
    constructor(
        public id: number,
        public name: string,
        public rarity: string,
        public count: number,
        public percent_from_pool: number,
        public balance_total: number,
    ) { }
}

export function mapToNft(data: any) {
    return new Nft(
        data.id,
        data.name,
        data.rarity,
        data.count,
        data.percent_from_pool,
        data.balance_total
    )
}