import { mapToSkin, Skin } from "./skin";

export class SkinsProvider {
    public static async getAll(userId: number): Promise<Skin[]> {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/clicker/skins/get/all/${userId}`)
            .then(response => response.json())
            .then(response => response);

        return (response.skins as any[]).map(mapToSkin);
    }

    public static async buy(skinId: any, date_now_timestamp: number, token: string): Promise<any> {
        let data = {
            "skin_id": skinId,
            "timestamp": date_now_timestamp
        };

        const response = await fetch(`${process.env.REACT_APP_API_URL}/v2/skins/buy`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'auth-api-token': token
            },
            body: JSON.stringify(data)
        }).then(response => response.json())
            .then(response => response)

        return response;
    }

    public static async set(skinId: any, date_now_timestamp: number, token: string): Promise<any> {
        let data = {
            "skin_id": skinId,
            "timestamp": date_now_timestamp
        };

        const response = await fetch(`${process.env.REACT_APP_API_URL}/v2/skins/set`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'auth-api-token': token!.toString()
            },
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(response => response)

        return response;
    }
}