import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Card } from '../../../Components/Predict/card/card';
import DrawIcon from '../../../Resources/Predict/icons/draw2.svg';
import CalendarIcon from '../../../Resources/Predict/icons/calendar.svg';
import BackIcon from '../../../Resources/Predict/icons/back.svg';
import CheckedIcon from '../../../Resources/Predict/icons/Checked.svg';
import CrossIcon from '../../../Resources/Predict/icons/cross.svg';
import CoinIcon from '../../../Resources/Predict/icons/coin.svg';
import TopRightArrow_light from '../../../Resources/Predict/icons/topRightArrow_light.svg';
import SelectedTopRightArrow_light from '../../../Resources/Predict/icons/selected_topRightArrow_light.svg';
import DownRightArrow_light from '../../../Resources/Predict/icons/downRightArrow_light.svg';
import SelectedDownRightArrow_light from '../../../Resources/Predict/icons/selected_downRightArrow_light.svg';
import style from './profileStyle.module.scss';
import { DateTime } from 'luxon';
import { UserProvider } from '../../../Domain/User/userProvider';
import GameContext from '../../../Context/GameContext';
import { UserGameHistory } from '../../../Domain/User/userGameHistory';
import PLoader from '../../../Components/Predict/loader/Loader';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Glide from '@glidejs/glide';
import { ActiveGame } from '../../../Domain/Predict/acitveGame';
import { DownButton } from '../../../Components/Predict/actionButton/downButton';
import { UpButton } from '../../../Components/Predict/actionButton/upButton';
import './sliderStyle.css'
import { initSwipeBehavior } from '@telegram-apps/sdk-react';
import { GameProcess } from '../../../Domain/Predict/gameProcess';
import { PredictionResultModal } from '../predictions/fast/resultModal/resultModal';

export function ProfileGameHistoryBlock() {
    const { token, score, userId } = useContext(GameContext);
    const predictBalance = useMemo(() => parseFloat((score / 1_000_000).toFixed(2)), [score])
    const navigate = useNavigate();

    const [gameHistory, setGameHistory] = useState<UserGameHistory[]>([]);
    const [activeGames, setActiveGames] = useState<ActiveGame[]>([]);
    const [currentDateTimeUtc, setCurrentDateTimeUtc] = useState<Date | null>(null);

    const [isOpenPredictionResultModal, setIsOpenPredictionResultModal] = useState<boolean>(false);
    const [resultGameProcess, setResultGameProcess] = useState<UserGameHistory | null>(null);

    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    const { t, i18n } = useTranslation();
    const [swipeBehavior] = initSwipeBehavior();

    useEffect(() => {
        loadGameHistory();
    }, [])

    async function loadGameHistory() {
        setIsLoaded(false);

        const { gameHistory, activeGames, currentDateTime } = await UserProvider.getGameHistory(token!);
        setGameHistory(gameHistory)
        setCurrentDateTimeUtc(currentDateTime);
        setActiveGames(activeGames);

        setIsLoaded(true);
    }

    const locale = i18n.language === 'ru' ? 'ru' : i18n.language === 'uk' ? 'uk' : 'en';

    const formatDate = (date: Date): string => {
        if (currentDateTimeUtc == null) return "";

        const now = DateTime.fromJSDate(currentDateTimeUtc).setLocale(locale);
        const inputDate = DateTime.fromJSDate(date).setLocale(locale);

        const minutesDifference = now.diff(inputDate, 'minutes').minutes;
        const hoursDifference = now.diff(inputDate, 'hours').hours;

        if (minutesDifference < 60) {
            return `${Math.floor(minutesDifference)} ${t('min ago')}`;
        } else if (now.hasSame(inputDate, 'day')) {
            return `${Math.floor(hoursDifference)} ${t('hours ago')}`;
        } else if (inputDate.hasSame(now.minus({ days: 1 }), 'day')) {
            return t("Yesterday");
        } else if (inputDate.startOf('week') <= now) {
            return inputDate.toFormat(`cccc '${t("at")}' HH:mm`)
        } else {
            return inputDate.toFormat('MMMM dd, yyyy');
        }
    };

    function inWin(gameHistory: UserGameHistory) {
        return gameHistory.won_money != null ? true : (gameHistory.coef_up == null || gameHistory.price_last == gameHistory.price_locked) ? null : false;
    }

    const sliderRef = useRef<HTMLDivElement | null>(null);
    const glideRef = useRef<Glide | null>(null);

    useEffect(() => {
        if (activeGames.length == 0) return;

        if (sliderRef.current) {
            glideRef.current = new Glide(sliderRef.current, {
                type: 'slider',
                startAt: 0,
                focusAt: 'center',
                perView: 3,
                gap: 10,
                swipeThreshold: 80,
                dragThreshold: 120,
                animationDuration: 600,
                rewind: false,
                breakpoints: {
                    1330: { perView: 1.2, gap: 10 },
                    1150: { perView: 1.2, gap: 10 },
                    730: { perView: 1.9, gap: 10 },
                    550: { perView: 1.5, gap: 10 },
                    480: { perView: 1.2, gap: 10 },
                },

            });
            glideRef.current.mount();
        }

    }, [activeGames]);

    function navigateToGameProcess(game: ActiveGame) {
        navigate(`/predict/predictions/${game.game_id}`)
    }

    function closePredictionResultModal() {
        setIsOpenPredictionResultModal(false);
        setResultGameProcess(null);
    };

    function openPredictionResultModal(gameHistory: UserGameHistory) {
        setIsOpenPredictionResultModal(true);
        setResultGameProcess(gameHistory);
    };

    if (!isLoaded) return <PLoader />

    return (
        <div className={style.profileGameHistoryBlock}>
            <div className={style.profileGameHistoryBlock_header}>
                <div className={style.profileGameHistoryBlock_balance}>
                    {t('Balance')}:
                    <span>{predictBalance.toLocaleString("en")} <img src={CoinIcon} alt="СoinIcon" /></span>
                </div>
            </div>
            <div>
                <div className={`slider-container ${style.sliderContainer}`}>
                    <div ref={sliderRef} className={`glide ${style.mainPageContainer_deals_slider}`}>
                        <div className="glide__track" data-glide-el="track">
                            <ul className="glide__slides">
                                {
                                    activeGames.map((game, index) => (
                                        <li className="glide__slide" key={index}>
                                            <div key={game.game_id} className={style.gameListContainer_gameCard} onClick={() => navigateToGameProcess(game)}>
                                                <div>
                                                    <div className={style.gameListContainer_gameCard_activeStatus}>
                                                        <div></div>
                                                        <span>
                                                            {t("Active")}
                                                        </span>
                                                    </div>
                                                    <div className={style.gameListContainer_gameCard_info}>
                                                        <div className={style.gameListContainer_gameCard_info_left}>
                                                            <img src={game.image ? `${process.env.REACT_APP_FILE_STORAGE_URL}/${game.image}` : ""} alt="" />
                                                        </div>
                                                        <div className={style.gameListContainer_gameCard_info_right}>
                                                            <div className={style.gameListContainer_gameCard_info_right_gameName}>
                                                                {game.name}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={style.gameListContainer_gameCard_stats}>
                                                        <div>
                                                            <img src={CoinIcon} alt="PixieDarkIcon" />
                                                            <span>{(+(+(game.price_bet ?? 0)).toFixed(2)).toLocaleString("en")} {t("Bet")}</span>
                                                        </div>
                                                        <div>
                                                            <img src={CalendarIcon} alt="CalendarIcon" />
                                                            {<span>{DateTime.fromJSDate(game.bet_datetime_at).setLocale(locale).toFormat('MMM d, yyyy')}</span>}
                                                        </div>
                                                    </div>
                                                </div>

                                                {
                                                    game.game_id != 4 &&
                                                    <div className={style.gameListContainer_gameCard_action}>
                                                        <DownButton
                                                            size='small'
                                                            title={game.button_name_down.split(' ')[0]}
                                                            subTitle={game.coef_down == null ? null : (+(+game.coef_down.toFixed(2))).toLocaleString('en')}
                                                            selected={game.type_bet == false}
                                                        />
                                                        <UpButton
                                                            size='small'
                                                            title={game.button_name_up.split(' ')[0]}
                                                            subTitle={game.coef_up == null ? null : (+(+game.coef_up.toFixed(2))).toLocaleString('en')}
                                                            selected={game.type_bet == true}
                                                        />
                                                    </div>
                                                }

                                                {
                                                    game.game_id == 4 &&
                                                    <div className={`${style.gameListContainer_gameCard_action} ${style.coef}`}>
                                                        <div className={style.gameListContainer_gameCard_action_coef}>
                                                            {game.button_name_down}
                                                            {(game.coef_down ?? 0) < (game.coef_up ?? 0)
                                                                ? <DownButton size='small' selected={game.type_bet == false} title={game.coef_down == null ? "0" : (+(+game.coef_down.toFixed(2))).toLocaleString('en')} withoutIcon />
                                                                : <UpButton size='small' selected={game.type_bet == false} title={game.coef_down == null ? "0" : (+(+game.coef_down.toFixed(2))).toLocaleString('en')} withoutIcon />
                                                            }
                                                        </div>
                                                        <div className={style.gameListContainer_gameCard_action_coef}>
                                                            {game.button_name_up}
                                                            {(game.coef_up ?? 0) < (game.coef_down ?? 0)
                                                                ? <DownButton size='small' selected={game.type_bet == true} title={game.coef_up == null ? "0" : (+(+game.coef_up.toFixed(2))).toLocaleString('en')} withoutIcon />
                                                                : <UpButton size='small' selected={game.type_bet == true} title={game.coef_up == null ? "0" : (+(+game.coef_up.toFixed(2))).toLocaleString('en')} withoutIcon />
                                                            }
                                                        </div>
                                                    </div>
                                                }

                                            </div>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {
                gameHistory.map((gameHistory, index) =>
                    <Card key={index} className={style.profileGameHistoryBlock_transactionContainer} onClick={() => openPredictionResultModal(gameHistory)}>
                        <div className={style.profileGameHistoryBlock_transaction}>
                            <div className={style.profileGameHistoryBlock_transactionLeft}>
                                <img src={inWin(gameHistory) == null ? DrawIcon : inWin(gameHistory) ? CheckedIcon : CrossIcon} alt="topUpIcon" />
                                <div className={style.profileGameHistoryBlock_transactionLeft_information}>
                                    <div className={style.profileGameHistoryBlock_transactionLeft_information_title}>
                                        <div>#{gameHistory.game_process_id}</div>
                                        <div className={style.profileGameHistoryBlock_transactionLeft_information_date}>
                                            <span>{formatDate(gameHistory.created_at)}</span>
                                        </div>
                                    </div>
                                    <div className={style.profileGameHistoryBlock_transactionLeft_information_title}>
                                        <div className={style.profileGameHistoryBlock_transactionLeft_information_title_name}>
                                            {gameHistory.game_name}
                                        </div>
                                    </div>
                                    <div className={style.profileGameHistoryBlock_transactionLeft_information_sub}>
                                        {/* <div>{t('Coeff Up')} {(+gameHistory.coef_up ?? 0).toFixed(2)}</div>
                                        <div>{t('Coeff Down')} {(+gameHistory.coef_down ?? 0).toFixed(2)}</div> */}

                                        <div>
                                            <img src={gameHistory.type_bet == true ? SelectedTopRightArrow_light : TopRightArrow_light} alt="TopRightArrow_light" />
                                            <span className={gameHistory.type_bet == true ? style.selectedUp : ""}>{(+(gameHistory?.coef_up ?? 0)).toFixed(2)}</span>
                                        </div>
                                        <div>
                                            <img src={gameHistory.type_bet == false ? SelectedDownRightArrow_light : DownRightArrow_light} alt="DownRightArrow_light" />
                                            <span className={gameHistory.type_bet == false ? style.selectedDown : ""}>{(+(gameHistory?.coef_down ?? 0)).toFixed(2)}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={style.profileGameHistoryBlock_transactionRight}>
                                <div className={style.profileGameHistoryBlock_transactionRight_amount}>
                                    <img src={CoinIcon} alt="pixIcon" />
                                    {
                                        inWin(gameHistory) != null
                                            ? inWin(gameHistory)
                                                ? <span>+{(+((gameHistory.won_money ?? 0).toFixed(2))).toLocaleString('en')}</span>
                                                : <span>-{(+gameHistory.price_bet).toLocaleString('en')}</span>
                                            : <span>0</span>
                                    }
                                </div>
                                {
                                    inWin(gameHistory) == true &&
                                    <div className={style.profileGameHistoryBlock_transactionRight_balance}>
                                        <span>{t('Bet')}: {(+gameHistory.price_bet).toLocaleString('en')}</span>
                                        <span>
                                            x{gameHistory.type_bet != null && gameHistory.type_bet ? (+((gameHistory.coef_up ?? 0).toFixed(2))).toLocaleString('en') : (+((gameHistory.coef_down ?? 0).toFixed(2))).toLocaleString('en')}
                                        </span>
                                    </div>
                                }
                            </div>
                        </div>

                        <div
                            className={`${style.profileGameHistoryBlock_transaction_gameInfo} ${!(gameHistory.balance_was != null && gameHistory.balance_has != null) ? style.single : ''}`}
                        >
                            {/* {[875591451, 939941303, 414081636].includes(userId)
                                ?
                                <>
                                    {gameHistory.balance_was != null && gameHistory.balance_has != null &&
                                        <div>
                                            Balance {(+(gameHistory.balance_was.toFixed(2))).toLocaleString('en')} -&gt; {(+(gameHistory.balance_has.toFixed([59, 60, 61].includes(gameHistory.game_id) ? 6 : 2))).toLocaleString('en')}
                                        </div>
                                    }
                                </>
                                : <div />
                            } */}

                            {
                                gameHistory.balance_was != null && gameHistory.balance_has != null &&
                                <div>
                                    {t("Balance")} {(+(gameHistory.balance_was.toFixed(2))).toLocaleString('en')} -&gt; {(+(gameHistory.balance_has.toFixed([59, 60, 61].includes(gameHistory.game_id) ? 6 : 2))).toLocaleString('en')}
                                </div>
                            }
                            {
                                gameHistory.price_locked != null && gameHistory.price_last != null &&
                                <div>
                                    {t("Price start")}: {(+(gameHistory.price_locked.toFixed([59, 60, 61].includes(gameHistory.game_id) ? 6 : 2)))} {t("Price end")} {(+(gameHistory.price_last.toFixed([59, 60, 61].includes(gameHistory.game_id) ? 6 : 2)))}
                                </div>
                            }
                        </div>
                    </Card>
                )
            }
            {
                resultGameProcess != null &&
                <PredictionResultModal isOpen={isOpenPredictionResultModal}
                    game_name={resultGameProcess.game_name}
                    player_price_bet={resultGameProcess.price_bet}
                    player_price_win={resultGameProcess.won_money}
                    price_last={resultGameProcess.price_last}
                    price_locked={resultGameProcess.price_locked}
                    in_game={resultGameProcess.type_bet}
                    game_id={resultGameProcess.game_id}
                    coef_up={resultGameProcess.coef_up}
                    coef_down={resultGameProcess.coef_down}
                    onClose={closePredictionResultModal} />
            }
        </div>
    )
}
