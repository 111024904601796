import '../App.css';
import { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import coinImage from "../Resources/images/coin.svg";
import GameContext from "../Context/GameContext";
import Loader from "../Components/Loader";
import { useTranslation } from "react-i18next";

import energyIcon from "../Resources/images/energy_icon.svg"
import { skins_images } from '../constants';
import { SkinsProvider } from '../Domain/Skins/skinsProvider';
import { Skin } from '../Domain/Skins/skin';

export default function Skins() {
    const { score, updateGame, userId, token, skinId } = useContext(GameContext);

    const [skins, setSkins] = useState<Skin[]>([]);
    const [currentSkin, setCurrentSkin] = useState<Skin | null>(null);

    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [viewPopup, setViewPopup] = useState<boolean>(false);

    const { t, i18n } = useTranslation();

    useEffect(() => {
        loadSkins();
    }, []);

    async function loadSkins() {
        const skins = await SkinsProvider.getAll(userId);
        setSkins(skins);

        setIsLoaded(true);
    }

    function clickCoinCard(skin: Skin) {
        if (skin.user_id !== null) return;

        setCurrentSkin(skin);
        setViewPopup(true);
    }

    function closePopup() {
        setViewPopup(false);
        setCurrentSkin(null);
    }

    async function buySkin(id: number) {
        if (currentSkin == null) return;
        if (score < currentSkin.price) return;

        setIsLoaded(false);

        let date_now_obj = new Date();
        let date_now_timestamp = date_now_obj.getTime();
        date_now_timestamp = date_now_timestamp / 1000;
        date_now_timestamp = date_now_timestamp;

        const response = await SkinsProvider.buy(id, date_now_timestamp, token!);
        if (response.message === "ok") {
            setViewPopup(false);
            setCurrentSkin(null);

            loadSkins();
        }

        updateGame({
            score: parseFloat(response.balance)
        })
    }

    async function changeSkin(skin: Skin) {
        let date_now_obj = new Date();
        let date_now_timestamp = date_now_obj.getTime();
        date_now_timestamp = date_now_timestamp / 1000;
        date_now_timestamp = date_now_timestamp;

        const response = await SkinsProvider.set(skin.id, date_now_timestamp, token!);
        if (response.message === "ok") {
            updateGame({
                skinId: skin.id,
                skinImageId: skin.image_id,
                score: parseFloat(response.balance)
            });

            window.location.reload();
        }

        updateGame({
            score: parseFloat(response.balance)
        });
    }

    if (!isLoaded) return <Loader />;

    return (
        <div className="App">
            <div className="improve_container">
                {/*<h1 className="improve_container-name">{t("improvements")}</h1>*/}

                <div className="improvements_header-buttons">
                    <NavLink to="/improve" className={({ isActive }) => `improvements_header-buttons-button ${isActive ? 'active' : ''}`} >
                        {t('improvements')}
                    </NavLink>
                    <NavLink to="/coins" className={({ isActive }) => `improvements_header-buttons-button ${isActive ? 'active' : ''}`}>
                        {t('coins')}
                    </NavLink>
                    <NavLink to="/skins" className={({ isActive }) => `improvements_header-buttons-button ${isActive ? 'active' : ''}`} >
                        {t('skins')}
                    </NavLink>
                </div>

                {
                    viewPopup && currentSkin &&
                    <div className="offline_profit_container coins">
                        <div className="offline_profit_container-content tasks coins">
                            <button className="offline_profit_container-content-button-close" onClick={() => { closePopup() }}>✕</button>
                            {/*<span className="offline_profit_container-content-text">{translatedName(setCurrentSkin())}</span>*/}
                            <img src={skins_images[currentSkin.image_id]} alt="" />
                            <span className="offline_profit_container-content-value">
                                <img src={coinImage} alt="" />
                                {currentSkin.price.toLocaleString()}
                            </span>
                            <div className="coins_container-row-item-boosts-popup">
                                <div className="coins_container-row-item-boosts-item">
                                    <img src={coinImage} alt="" />
                                    Boost per tap
                                    +{currentSkin.per_tap_boost}
                                </div>
                                <div className="coins_container-row-item-boosts-item">
                                    <img src={energyIcon} alt="" />
                                    Energy bar boost
                                    +{currentSkin.energy_bar_boost}%
                                </div>
                                <div className="coins_container-row-item-boosts-item">
                                    <img src={coinImage} alt="" />
                                    Per hour boost
                                    +{currentSkin.earning_boost}%
                                </div>
                            </div>
                            <div className="popup_tasks_buttons-bottom">
                                <div className="popup_tasks_buttons">
                                    <button className="popup_tasks_buttons-button" onClick={() => closePopup()}>{t('Close')}</button>
                                    <button className={"popup_tasks_buttons-button second " + (score < currentSkin.price ? 'disabled' : '')} onClick={() => { buySkin(currentSkin.id) }}>{t('Buy')}</button>
                                </div>
                            </div>
                        </div>
                        <div className="offline_profit_container-overlay" onClick={() => { closePopup() }}></div>
                    </div>
                }

                <div className="coins_container-row">
                    {
                        skins.map((skin, index) => (
                            <div key={index} className={"coins_container-row-item " + (skin.user_id ? 'buyed' : '')} key-id={skin.id} onClick={() => clickCoinCard(skin)}>
                                <div className="coins_container-row-item-boosts">
                                    <div className="coins_container-row-item-boosts-item">
                                        <img src={coinImage} alt="" />
                                        +{skin.per_tap_boost}
                                    </div>
                                    <div className="coins_container-row-item-boosts-item">
                                        <img src={energyIcon} alt="" />
                                        +{skin.energy_bar_boost}%
                                    </div>
                                    <div className="coins_container-row-item-boosts-item">
                                        <img src={coinImage} alt="" />
                                        +{skin.earning_boost}%
                                    </div>
                                </div>
                                <img src={skins_images[skin.image_id]} alt="" />
                                {/*<span className="coins_container-row-item-name">{translatedName(skin)}</span>*/}
                                <span className="coins_container-row-item-price">
                                    {
                                        skin.user_id
                                            ? skin.skin_id === skinId
                                                ? t('Selected')
                                                : <button className="coins-button-choose" onClick={() => changeSkin(skin)}>{t('Choose')}</button>
                                            : <><img src={coinImage} alt="" />{(skin.price).toLocaleString('en')}</>
                                    }
                                </span>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    );
}
