import React, { useEffect, useState } from 'react'
import style from './pixieFamiliesStyle.module.scss'
import contestsPhoto from '../../../Resources/Predict/images/Contests.png'
import { Card } from '../../../Components/Predict/card/card';

interface Contests {
    name: string;
    description: string;
    photo: string;
}

export function ContestsBlock() {
    const [contests, setContests] = useState<Contests[]>([]);

    useEffect(() => {
        loadContests();
    }, [])

    function loadContests() {
        setContests([
            { name: "Contest A", description: 'Description', photo: contestsPhoto },
            { name: "Contest B", description: 'Description', photo: contestsPhoto },
            { name: "Contest C", description: 'Description', photo: contestsPhoto },
            { name: "Contest D", description: 'Description', photo: contestsPhoto },
            { name: "Contest D", description: 'Description', photo: contestsPhoto },
            { name: "Contest D", description: 'Description', photo: contestsPhoto },
            { name: "Contest D", description: 'Description', photo: contestsPhoto },
            { name: "Contest D", description: 'Description', photo: contestsPhoto },
            { name: "Contest D", description: 'Description', photo: contestsPhoto },
            { name: "Contest D", description: 'Description', photo: contestsPhoto },
            { name: "Contest D", description: 'Description', photo: contestsPhoto },
            { name: "Contest D", description: 'Description', photo: contestsPhoto },
            { name: "Contest D", description: 'Description', photo: contestsPhoto },
            { name: "Contest D", description: 'Description', photo: contestsPhoto },
        ]);
    }

    return (
        <div className={style.contestsBlock}>
            {
                contests.map((contest, index) =>
                    <Card key={index} className={style.contestsBlock_contestCard}>
                        <div className={style.contestsBlock_contestCard_left}>
                            <img src={contest.photo} alt="contestPhoto" />
                            <div className={style.contestsBlock_contestCard_left_info}>
                                <div className={style.contestsBlock_contestCard_left_info_title}>
                                    {contest.name}
                                </div>
                                <div className={style.contestsBlock_contestCard_left_info_subTitle}>
                                    {contest.description}
                                </div>
                            </div>
                        </div>
                        <div className={style.contestsBlock_contestCard_status}>
                            New
                        </div>
                    </Card>
                )
            }
        </div>
    )
}
