export class CodesProvider {

    public static async checkCode(codeValue: string, token: string): Promise<any> {
        let data = {
            "code": codeValue
        };

        const response = await fetch(`${process.env.REACT_APP_API_URL}/v2/codes/check`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'auth-api-token': token!
            },
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(response => response)

        return response;
    }
}