import style from './loaderStyle.module.scss'

interface IProps {
    color?: "light" | "dark";
}

export default function MiniLoader(props: IProps) {
    const color = props.color ?? "dark"

    return (
        <span className={`${style.miniLoader} ${color == 'light' ? style.light : style.dark}`}></span>
    );
};