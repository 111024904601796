import React, { useState } from 'react'
import { User } from '../predictions/fast/fastPredictionPage'
import style from './profileStyle.module.scss'
import UsdtIcon from '../../../Resources/Predict/icons/USDT.svg'
import CreditCardIcon from '../../../Resources/Predict/icons/CreditCard.svg'
import MetamaskIcon from '../../../Resources/Predict/icons/Metamask.svg'
import { RoundedTextInput } from '../../../Components/Predict/inputs/roundedTextInput/roundedTextInput'
import { RoundedSelect } from '../../../Components/Predict/inputs/roundedSelect/roundedSelect'
import { ToggleSwitch } from '../../../Components/Predict/inputs/toggleSwitch/toggleSwitch'

export enum WalletType {
    MetamaskConnected = 1,
    USDT = 2,
    CreditCard = 3
}

export function ProfileInfoBlock() {
    const user = { id: 1, avatar: "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Circle-icons-profile.svg/1200px-Circle-icons-profile.svg.png", name: 'John Doe', email: "john.doe@example.com", wallet: WalletType.MetamaskConnected, _2FAStatus: false }

    const [userBlank, setUserBlank] = useState<User>(user)


    function getWalletTypeDisplayName(walletType: WalletType | null) {
        if (walletType == null) return "";

        switch (walletType) {
            case WalletType.MetamaskConnected: return "Metamask Connected";
            case WalletType.USDT: return "USDT Wallet";
            case WalletType.CreditCard: return "Credit Card";
        }
    }

    return (
        <div className={style.profileInfoBlock}>
            <div className={style.profileInfoBlock_input}>
                <div>Name:</div>
                <RoundedTextInput value={userBlank.name} onChange={(name) => { setUserBlank(prev => ({ ...prev, name })) }} />
            </div>
            <div className={style.profileInfoBlock_input}>
                <div>Email:</div>
                <RoundedTextInput value={userBlank.email} onChange={(email) => { setUserBlank(prev => ({ ...prev, email })) }} />
            </div>
            <div className={style.profileInfoBlock_input}>
                <div>Wallet:</div>
                <RoundedSelect
                    value={userBlank.wallet}
                    options={[
                        { value: WalletType.MetamaskConnected, icon: MetamaskIcon },
                        { value: WalletType.USDT, icon: UsdtIcon },
                        { value: WalletType.CreditCard, icon: CreditCardIcon }
                    ]}
                    getOptionLabel={option => getWalletTypeDisplayName(option)}
                    getOptionValue={option => option!}
                    onChange={wallet => { setUserBlank(prev => ({ ...prev, wallet })) }}
                    placeholder='Select Wallet'

                />
            </div>
            <div className={style.profileInfoBlock_checkBox}>
                <span>2FA Status:</span>
                <ToggleSwitch
                    value={userBlank._2FAStatus}
                    onChange={_2FAStatus => { setUserBlank(prev => ({ ...prev, _2FAStatus })) }}
                />
            </div>
            <div className={style.profileInfoBlock_saveButton}>
                Save Changes
            </div>
        </div>
    )
}
