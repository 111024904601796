import '../App.css';
import { useContext, useEffect, useRef, useState, useTransition } from "react";
import { NavLink } from "react-router-dom";
import energyIcon from "../Resources/images/energy_icon.svg"
import energyDarkIcon from "../Resources/images/energy_icon_dark.svg"
import rocketIcon from "../Resources/images/rocket.svg"
import rocketDarkIcon from "../Resources/images/rocket_dark.svg"
import GameContext from "../Context/GameContext";
import WebApp from "@twa-dev/sdk";
import WebAppUser from "@twa-dev/sdk";
import coinImage from "../Resources/images/coin.svg";
import default_skin from "../Resources/images/human-free.png";
import { coins_images, levels_score, skins_images } from '../constants';
import { Click } from '../Domain/Clicker/click';
import { ClickerProvider } from '../Domain/Clicker/clickerProvider';
import { useTheme } from '../Context/Predict/themeContext';
import menu_improvements_dark from '../Resources/images/menu/new/improvements_dark.svg';
import menu_improvements_light from '../Resources/images/menu/new/improvements_light.svg';
import menu_improvements from '../Resources/images/menu/new/improvements.svg';
import { useTranslation } from 'react-i18next';

export default function Index() {
    const {
        score, eventTimeEndSecond, coinsPerClick, energy, totalEarn,
        maxEnergy, updateGame, token, event_clicks_counter, userId,
        coinImageId, skinImageId, coinShadowColor
    } = useContext(GameContext);

    const [clicks, setClicks] = useState<Click[]>([]);
    const [clicksCount, setClicksCount] = useState<number>(1);
    const [levelPercents, setLevelPercents] = useState<number>(0);
    const [levelValue, setLevelValue] = useState<number>(0);
    const [startTouchTime, setStartTouchTime] = useState<number | null>(null);

    const [isClicked, setIsClicked] = useState(false);
    const { t } = useTranslation();

    const { clickerTheme } = useTheme();

    useEffect(() => {
        levels_score.forEach((level, i) => {
            if (levels_score[i][0] <= totalEarn && levels_score[i][1] >= totalEarn) {
                setLevelPercents(totalEarn / (levels_score[i][1] / 100));
                updateGame({ level: i });
                setLevelValue(i);
            }
        });
    }, []);

    useEffect(() => {
        levels_score.forEach((level, i) => {
            if (levels_score[i][0] <= totalEarn && levels_score[i][1] >= totalEarn) {
                setLevelPercents(totalEarn / (levels_score[i][1] / 100));
                updateGame({ level: i });
                setLevelValue(i);
            }
        });
    }, [totalEarn]);

    const timer = useRef<NodeJS.Timeout | null>(null);

    function coinStartTouch() {
        let date_now = new Date();
        setStartTouchTime(date_now.getTime() / 1000);
    }

    function handleClick(event: React.TouchEvent): void {
        if (startTouchTime == null) throw new Error("startTouchTime null on handleClick coin");

        let date_now = new Date();
        let date_now_timestamp = date_now.getTime() / 1000;

        let start_end_touch_diff = date_now_timestamp - startTouchTime;
        if (start_end_touch_diff >= 30 && start_end_touch_diff <= 36) {
            console.log("ПОЛУЧИ СКИН")
        }
        let coinsPerClickNow = coinsPerClick;
        let isMinusEnergy = true;

        if (energy < coinsPerClick) {
            return;
            // coinsPerClickNow = 1;
            // isMinusEnergy = false;
        }

        if (timer.current) {
            clearTimeout(timer.current);
        }

        // Устанавливаем новый таймер
        timer.current = setTimeout(() => {
            sendRequest(clicksCount);
            setClicksCount(1);
        }, 1500); // Задержка в 1.5 секунды

        setClicksCount(clicksCount + 1);

        if (WebApp) {
            WebApp.HapticFeedback.impactOccurred('medium');
        }

        const touch = event.changedTouches[0];

        const newClick = new Click(
            Date.now(),
            touch.clientX,
            touch.clientY,
            score < 30_000_000 ? 10_000 : coinsPerClickNow
        );

        setClicks(currentClicks => [...currentClicks, newClick]);

        setTimeout(() => {
            setClicks(currentClicks => currentClicks.filter(item => item.id !== newClick.id));
        }, 1000); // Удаляем элемент через 1 секунду

        setIsClicked(true);
        // setScore(score + coinsPerClick);
        updateGame({
            score: score + (score < 30_000_000 ? 10_000 : coinsPerClickNow),
            energy: energy - (isMinusEnergy ? coinsPerClick : 0),
            totalEarn: totalEarn + (score < 30_000_000 ? 10_000 : coinsPerClickNow),
            event_clicks_counter: event_clicks_counter + (score < 30_000_000 ? 10_000 : coinsPerClickNow)
        });

        setTimeout(() => setIsClicked(false), 100); // Убрать эффект через 100 мс
    };

    async function sendRequest(clicks_count: number) {
        let date_now_obj = new Date();
        let date_now_timestamp = date_now_obj.getTime();
        date_now_timestamp = date_now_timestamp / 1000;
        date_now_timestamp = date_now_timestamp - 2;

        const response = await ClickerProvider.tap(clicks_count, date_now_timestamp, token!);
        date_now_obj = new Date();
        date_now_timestamp = date_now_obj.getTime() / 1000;

        updateGame({
            energy: parseInt(response.current_energy),
            score: parseFloat(response.balance),
            event_clicks_counter: parseInt(response.clicks_counter)
        })
    }

    function eventEndTime(seconds: number) {
        // Определение количества часов, минут и секунд
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;

        // Добавление ведущих нулей, если необходимо
        const formattedHours = hours.toString().padStart(2, '0');
        const formattedMinutes = minutes.toString().padStart(2, '0');
        const formattedSeconds = remainingSeconds.toString().padStart(2, '0');

        // Формирование итоговой строки
        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    }

    // const StyledClicker = styled.div`
    //     width: 70vw;
    //     height: 70vw;
    //     margin: 0 auto;
    //     outline: none;
    //     border: none;
    //     border-radius: 50%;
    //     background-image: url(${"Resources/images/clicker-background-default.svg"});
    //     background-position: center;
    //     background-size: 102%;
    //     box-shadow: 0px 0px 90px 0px #90a3b0;
    //     user-select: none;
    //     -webkit-user-select: none;
    //     -moz-user-select: none;
    //     -ms-user-select: none;
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    //     box-shadow: ${isClicked ? ('0px 0px 110px 0px ' + coinShadowColor) : ('0px 0px 90px 0px ' + coinShadowColor)};
    //     background-image: url(${coins_images[coinImageId]})
    // `;

    let afterStyles = `
        .clicker::after{
            content: "";
            width: 190px;
            height: 190px;
            display: block;
            background-image: url(${skinImageId ? skins_images[skinImageId] : default_skin});
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;
        }
    `
    return (
        <div className="App">
            <style>{afterStyles}</style>
            <div>
                <div className="level-content">
                    <div className="level-content-level">
                        <div className="energy_container">
                            <img src={clickerTheme == null ? energyIcon : energyDarkIcon} alt="" />
                            {energy}/{maxEnergy}
                        </div>
                        {/* <span className="level-content-value">Level: {levelValue}</span> */}
                        <NavLink to="/leaderboard" className="level-content-leaderboard">{t("Leaderboard")} &gt;</NavLink>
                    </div>
                    <div className="level-line-container">
                        <div className="level-line" style={{ width: levelPercents + '%' }}></div>
                    </div>
                </div>

                {
                    coinImageId ?
                        (
                            coinImageId === 9 ?
                                <div className={"clicker pixie-golden-event-coin " + (energy >= coinsPerClick ? '' : 'disabled')} onTouchStart={coinStartTouch} onTouchEnd={handleClick} style={{
                                    boxShadow: isClicked ? ('0px 0px 110px 0px ' + coinShadowColor) : ('0px 0px 90px 0px ' + coinShadowColor),
                                    backgroundImage: `url(${coins_images[coinImageId]})`
                                }}></div>
                                : coinImageId === 12 ?
                                    <div className={"clicker" + (energy >= coinsPerClick ? '' : 'disabled')} onTouchStart={coinStartTouch} onTouchEnd={handleClick} style={{
                                        boxShadow: isClicked ? ('0px 0px 110px 0px ' + coinShadowColor) : ('0px 0px 90px 0px ' + coinShadowColor),
                                        backgroundImage: `url(${coins_images[coinImageId]})`,
                                        backgroundSize: '120%'
                                    }}></div>
                                    : coinImageId === 11 ?
                                        <div className={"clicker" + (energy >= coinsPerClick ? '' : 'disabled')} onTouchStart={coinStartTouch} onTouchEnd={handleClick} style={{
                                            boxShadow: isClicked ? ('0px 0px 110px 0px ' + coinShadowColor) : ('0px 0px 90px 0px ' + coinShadowColor),
                                            backgroundImage: `url(${coins_images[coinImageId]})`,
                                            backgroundSize: '115%'
                                        }}></div>
                                        : coinImageId === 10 ?
                                            <div className={"clicker" + (energy >= coinsPerClick ? '' : 'disabled')} onTouchStart={coinStartTouch} onTouchEnd={handleClick} style={{
                                                boxShadow: isClicked ? ('0px 0px 110px 0px ' + coinShadowColor) : ('0px 0px 90px 0px ' + coinShadowColor),
                                                backgroundImage: `url(${coins_images[coinImageId]})`,
                                                backgroundSize: '108%'
                                            }}></div>
                                            : coinImageId === 13 ?
                                                <div className={"clicker the-one-coin " + (energy >= coinsPerClick ? '' : 'disabled')} onTouchStart={coinStartTouch} onTouchEnd={handleClick} style={{
                                                    boxShadow: isClicked ? ('0px 0px 110px 0px ' + coinShadowColor) : ('0px 0px 90px 0px ' + coinShadowColor),
                                                    backgroundImage: `url(${coins_images[coinImageId]})`,
                                                    backgroundSize: '110%'
                                                }}></div>
                                                : <div className={"clicker " + (energy >= coinsPerClick ? '' : 'disabled')} onTouchStart={coinStartTouch} onTouchEnd={handleClick} style={{
                                                    boxShadow: isClicked ? ('0px 0px 110px 0px ' + coinShadowColor) : ('0px 0px 90px 0px ' + coinShadowColor),
                                                    backgroundImage: `url(${coins_images[coinImageId]})`
                                                }}>
                                                </div>
                        )
                        :
                        <div className={"clicker " + (energy >= coinsPerClick ? '' : 'disabled')} onTouchStart={coinStartTouch} onTouchEnd={handleClick}
                            style={isClicked ? { boxShadow: '0px 0px 110px 0px #AD5519' } : {}}>
                        </div>
                }

                <span className="mainpage coin-timer">{eventTimeEndSecond > 0 ? eventEndTime(eventTimeEndSecond) : ''}</span>

                {
                    clicks.map(({ id, x, y, coins_per_click_now: coinsPerClickNow }) => (
                        <div className="tap-value-with-coin-image"
                            key={id}
                            style={{
                                position: 'fixed',
                                left: x,
                                top: y,
                                opacity: 1,
                                animation: 'fly 1.5s forwards',
                                pointerEvents: 'none',
                                color: '#fff',
                                fontFamily: 'Pixel',
                                fontSize: '28px'
                            }}
                        >
                            <img src={coinImage} alt="" />
                            {coinsPerClickNow}
                        </div>
                    ))
                }

                <div className="energy_and_boost">
                    {/* <div className="energy_container">
                        <img src={clickerTheme == null ? energyIcon : energyDarkIcon} alt="" />
                        {energy}/{maxEnergy}
                    </div> */}

                    <NavLink to="/improve" className="boost_button">
                        <img src={clickerTheme == null ? menu_improvements : clickerTheme == 'dark' ? menu_improvements_dark : menu_improvements_light} alt="Improvements" />
                        {t("improve")}
                    </NavLink>

                    <NavLink to="/boosts" className="boost_button">
                        <img src={clickerTheme == null ? rocketIcon : rocketDarkIcon} alt="" />
                        {t("boost")}
                    </NavLink>
                </div>

                {
                    userId === 875591451 &&
                    <span className="admin-text">
                        {
                            (WebAppUser.initDataUnsafe && WebAppUser.initDataUnsafe.user) && WebAppUser.initDataUnsafe.user.language_code
                        }
                    </span>
                }
            </div>
        </div>
    );
}
