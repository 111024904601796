export class BoostsProvider {
    public static async buyMultitap(token: string): Promise<any> {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/v2/boosts/multitap/buy`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'auth-api-token': token
            }
        }).then(response => response.json())
            .then(response => response)

        return response;

    }

    public static async buyEnergytotal(token: string): Promise<any> {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/v2/boosts/energytotal/buy`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'auth-api-token': token
            }
        }).then(response => response.json())
            .then(response => response)

        return response;
    }

    public static async buyEnergypersecond(token: string): Promise<any> {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/v2/boosts/energypersecond/buy`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'auth-api-token': token!
            }
        }).then(response => response.json())
            .then(response => response)

        return response;
    }
}