import React, { useState } from 'react'
import style from './roundedSelectStyle.module.scss'
import DownArrowIcon from '../../../../Resources/Predict/icons/downArrow1.svg'
import DownDarkArrowIcon from '../../../../Resources/Predict/icons/downArrow_dark.svg'
import { useTheme } from '../../../../Context/Predict/themeContext';

export interface IOption<TValue> {
    icon?: string;
    value: TValue;
}

interface IProps<TValue> {
    options: IOption<TValue>[];
    value: TValue;
    onChange: (value: TValue) => void;
    getOptionLabel: (value: TValue) => string
    getOptionValue: (value: TValue) => string | number
    placeholder: string;
    isError?: boolean;
}

export function RoundedSelect<TValue>(props: IProps<TValue>) {
    const [isOpen, setIsOpen] = useState(false);

    const { theme } = useTheme();

    const handleSelect = (optionValue: TValue) => {
        props.onChange(optionValue);
        setIsOpen(false);
    };

    const selectedOption = props.options.find(option => props.getOptionValue(props.value) === props.getOptionValue(option.value as unknown as TValue));

    return (
        <div className={style.selectWrapper}>
            <div
                className={`${style.select} ${props.isError ? style.error : ""}`}
                onClick={() => setIsOpen(!isOpen)}
            >
                <div className={style.select_left}>
                    {
                        selectedOption?.icon != null &&
                        <img src={selectedOption.icon} alt="selectedOptionIcon" />
                    }

                    <span> {props.getOptionLabel(props.value) || props.placeholder}</span>
                </div>

                <div className={style.select_right}>
                    <img src={theme == 'light' ? DownArrowIcon : DownDarkArrowIcon} alt="" />
                </div>

            </div>
            {
                isOpen && (
                    <div className={style.options}>
                        {
                            props.options.map(option => (
                                <div
                                    key={props.getOptionValue(option.value as unknown as TValue)}
                                    className={style.option}
                                    onClick={() => handleSelect(option.value as unknown as TValue)}
                                >
                                    {
                                        option?.icon != null &&
                                        <img src={option.icon} alt="optionIcon" />
                                    }
                                    <span>{props.getOptionLabel(option.value)}</span>
                                </div>
                            ))
                        }
                    </div>
                )
            }
        </div>
    );
}
