import { Category, mapToCategory } from "./category";

export class CatergoryProvider {
    public static async get(token: string): Promise<Category[]> {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/predicts/v1/categories/list`,
            {
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                    'x-api-key': token
                },
            }
        )
            .then(response => response.json())
            .then(response => {
                return response
            });


        return (response.categories as any[]).map(mapToCategory);
    }
}