import { useContext, useEffect, useMemo, useState } from 'react';
import toast, { Toaster } from "react-hot-toast";
import { useTranslation } from 'react-i18next';
import PLoader from '../../../../Components/Predict/loader/Loader';
import GameContext from '../../../../Context/GameContext';
import { Game } from '../../../../Domain/Predict/game';
import { PredictProvider } from '../../../../Domain/Predict/predictProdiver';
import './style.scss'
import { GameProcess, GameProcessType } from '../../../../Domain/Predict/gameProcess';
import { initSwipeBehavior } from '@telegram-apps/sdk-react';
import { BetType, SetPositionModal } from '../fast/setPositionModal/setPositionModal';
import { PredictionCard } from '../fast/card/predictionCard';
import { CryptocurrencyProvider } from '../../../../Domain/Cryptocurrency/cryptocurrencyProvider';
import { CryptocurrencyAll } from '../../../../Domain/Cryptocurrency/cryptocurrencyAll';
import { useNavigate } from 'react-router-dom';
import { GameCard } from '../predictionsPage';

export default function ActivePredictionsPage() {
    const { token, updateGame } = useContext(GameContext);

    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    const [games, setGames] = useState<Game[]>([]);

    const [gamesProcess, setGamesProcess] = useState<GameProcess[]>([]);
    const [selectedGameProcess, setSelectedGameProcess] = useState<GameProcess | null>(null);
    const [selectedBetType, setSelectedBetType] = useState<BetType | null>(null);

    const [dateTimeUtcNow, setDateTimeUtcNow] = useState<Date>(new Date());

    const [isOpenSetPositionPredictionCard, setIsOpenSetPositionPredictionCard] = useState<boolean>(false);

    const [swipeBehavior] = initSwipeBehavior();
    const { t } = useTranslation();

    const fastPredictionIds = [1, 3, 56, 59, 60, 61, 69, 89, 90, 91]

    useEffect(() => {
        if (swipeBehavior.supports("disableVerticalSwipe")) swipeBehavior.disableVerticalSwipe();

        const updatePlayerCountInterval = setInterval(() => { updateGameProcess(); }, 5_000);
        const updateTimeTimerInterval = setInterval(() => { loadCurrentPrice(); }, 5_000);

        loadGames();
        loadGameProcess();

        return () => {
            clearInterval(updatePlayerCountInterval);
            clearInterval(updateTimeTimerInterval);

            if (swipeBehavior.supports("enableVerticalSwipe")) swipeBehavior.enableVerticalSwipe();
        }
    }, [])

    useEffect(() => {
        loadCurrentPrice();
    }, [JSON.stringify(gamesProcess.map(g => g.id))])

    async function loadGames() {
        const games = await PredictProvider.getGames(-1, token!);
        setGames(games);
    }

    async function updateGameProcess() {
        const { gamesProcess, dateTimeUtcNow } = await PredictProvider.getGamesProcess(token!);
        const liveGames = gamesProcess.filter(g => g.type == GameProcessType.MoneyCollection);

        setDateTimeUtcNow(dateTimeUtcNow);
        setGamesProcess(prev => {
            return prev.map(game => {
                const liveGame = liveGames.find(liveGame => liveGame.id === game.id);
                if (liveGame?.id == null) return game;

                return {
                    ...game,
                    players_in_game: liveGame.players_in_game,
                    prize_pool: liveGame.prize_pool,
                    coef_down: liveGame.coef_down,
                    coef_up: liveGame.coef_up
                };
            });
        });

    }

    async function loadCurrentPrice() {
        const cryptocurrencyAll = await CryptocurrencyProvider.getAll(token!);

        setGamesProcess(prev => {
            return prev.map(gamePrecess => {
                if (gamePrecess.type === GameProcessType.CurrentlyActive || gamePrecess.type === GameProcessType.MoneyCollection && fastPredictionIds.includes(gamePrecess.game_id)) {
                    const currentPrice = getCurrentPriceValue(gamePrecess.name, cryptocurrencyAll);
                    return { ...gamePrecess, current_price: currentPrice };
                }
                return gamePrecess;
            });
        });

        setTimeout(() => {
            setGamesProcess(prev => {
                return prev.map(gamePrecess => {
                    if (gamePrecess.type === GameProcessType.CurrentlyActive || gamePrecess.type === GameProcessType.MoneyCollection && fastPredictionIds.includes(gamePrecess.game_id)) {
                        let direction = true;
                        if (gamePrecess.price_locked != null) {
                            const difference = ((gamePrecess.current_price ?? (gamePrecess.price_last ?? 0)) - gamePrecess.price_locked)
                            direction = difference < 0 ? false : true;
                        }

                        const currentPrice = (+getCurrentPriceValue(gamePrecess.name, cryptocurrencyAll));
                        const persentCurrentPrice = direction ? currentPrice + (currentPrice * 0.03 / 100) : currentPrice - (currentPrice * 0.03 / 100);
                        return { ...gamePrecess, current_price: persentCurrentPrice };
                    }
                    return gamePrecess;
                });
            });
        }, 2_500)
    }

    function getCurrentPriceValue(gameName: string, cryptocurrencyAll: CryptocurrencyAll): number {
        switch (gameName) {
            case "TON": return cryptocurrencyAll.ton_price;
            case "NOT": return cryptocurrencyAll.not_price;
            case "DOGS": return cryptocurrencyAll.dogs_price;
            case "HAMSTER": return cryptocurrencyAll.hmstr_price;
            case "ETH": return cryptocurrencyAll.eth_price;
            case "BTC": return cryptocurrencyAll.btc_pricew;
            case "BTC: min 50 coins!": return cryptocurrencyAll.btc_pricew;
            case "DOGE": return cryptocurrencyAll.doge_price;
            case "SHIB": return cryptocurrencyAll.shib_price;
            case "PEPE": return cryptocurrencyAll.pepe_price;

            default: throw new Error(`error type ${gameName}`)
        }
    }

    async function loadGameProcess() {
        const { gamesProcess, dateTimeUtcNow } = await PredictProvider.getGamesProcess(token!);
        setGamesProcess(gamesProcess);
        setDateTimeUtcNow(dateTimeUtcNow);

        setIsLoaded(true);
    }

    async function updateBalance() {
        const balance = await PredictProvider.getBalance(token!);
        updateGame({ score: parseFloat(balance.toString()) });
    }

    async function closeSetPositionModal(inBet: boolean) {
        setIsOpenSetPositionPredictionCard(false);
        setSelectedBetType(null);
        setSelectedGameProcess(null);

        if (inBet) {
            toast.success(t("Success"));

            loadGameProcess();
            updateBalance();
        }
    }

    function setDownPosition(gameProcess: GameProcess) {
        if (gameProcess.in_game != null) return;

        setIsOpenSetPositionPredictionCard(true);
        setSelectedBetType(BetType.Down);
        setSelectedGameProcess(gameProcess);
    }

    function setUpPosition(gameProcess: GameProcess) {
        if (gameProcess.in_game != null) return;

        setIsOpenSetPositionPredictionCard(true);
        setSelectedBetType(BetType.Up);
        setSelectedGameProcess(gameProcess);
    }

    function formatTime(seconds: number) {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;

        return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
    }

    if (!isLoaded) return <PLoader />

    return (
        <div>
            <div className='mainPageContainer-card-list'>
                {
                    games.length > 0 && gamesProcess.length > 0 &&
                    gamesProcess.filter(g => !([92, 95, 96].includes(g.id))).map((gameProcess, index) => {
                        const game = games.find(g => gameProcess.game_id == g.id)!;

                        const dif = (dateTimeUtcNow.getTime() - gameProcess.created_at.getTime()) / 1000; // разница в секундах
                        const minutes = Math.ceil(dif / 60);

                        return (
                            <div key={index}>
                                {
                                    fastPredictionIds.includes(game.id)
                                        ? <div>
                                            <PredictionCard
                                                game={game}
                                                showGameHeader
                                                gameProcess={gameProcess}
                                                // index={minutes <= 1 ? 1 : minutes}
                                                onDown={() => setDownPosition(gameProcess)}
                                                onUp={() => setUpPosition(gameProcess)}
                                                canSelect={gameProcess.in_game == null}
                                            />
                                        </div>
                                        : <GameCard game={game} />
                                }
                            </div>
                        )
                    })
                }
            </div>
            {
                selectedBetType != null && selectedGameProcess != null &&
                <SetPositionModal betType={selectedBetType} isOpen={isOpenSetPositionPredictionCard} gameProcess={selectedGameProcess} onClose={closeSetPositionModal} />
            }
            <div><Toaster /></div>
        </div>
    )
}
