import style from './profileStyle.module.scss'
import PixieLogo from '../../../Resources/Predict/images/PixieLogo.png'
import BackIcon from '../../../Resources/Predict/icons/backSmall.svg'
import smallInfoIcon from '../../../Resources/Predict/icons/smallinfo.svg'
import CoinIcon from '../../../Resources/Predict/icons/coin.svg'
import { useNavigate, useParams } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react'
import { PredictProvider } from '../../../Domain/Predict/predictProdiver'
import GameContext from '../../../Context/GameContext'
import { Nft } from '../../../Domain/Predict/nft'
import PLoader from '../../../Components/Predict/loader/Loader'
import LicenceNftIcon from '../../../Resources/Predict/images/nft/Licence.jpg'
import LandsNftIcon from '../../../Resources/Predict/images/nft/Lands.jpeg'
import HousesNftIcon from '../../../Resources/Predict/images/nft/Houses.jpg'
import HousesDiamondNftIcon from '../../../Resources/Predict/images/nft/HousesDiamond.jpg'

enum Tab {
    Overview = 1,
    Statistics = 2,
    NFTsales = 3
}

export function ProfileNftBlock() {
    const { token } = useContext(GameContext);

    const [nft, setNft] = useState<Nft | null>(null);
    const [selectedTab, setSelectedTab] = useState<Tab>(Tab.Overview);
    const navigate = useNavigate();

    const { id } = useParams();

    useEffect(() => {
        loadNft();
    }, [id])

    async function loadNft() {
        if (id == null) return;

        const nft = await PredictProvider.getNft(token!, +id);
        setNft(nft);
    }

    return (
        <div className={style.profileNftBlock}>
            {nft != null
                ?
                <>
                    <div className={style.profileNftBlock_header}>
                        <div className={style.profileNftBlock_title}>
                            <div className={style.profileNftBlock_header_back} onClick={() => navigate(-1)}>
                                <img src={BackIcon} alt="BackIcon" /></div>
                            NFTs Details
                        </div>
                    </div>
                    <div className={style.profileNftBlock_content}>
                        <div className={style.profileNftBlock_content_image}>
                            <img src={nft.name == 'Licences' ? LicenceNftIcon : nft.name == 'Lands' ? LandsNftIcon : nft.rarity == 'Diamond' ? HousesDiamondNftIcon : HousesNftIcon} alt="fullNft" />
                        </div>
                        <div className={style.profileNftBlock_content_title}>
                            Pixie NFT Mystery Box
                        </div>

                        <div className={style.profileNftBlock_content_subtitle}>
                            This Mystery box contains one of the 3 types of NFTS: House, Land, and Licence. There are 5 levels of rarity that make you earn differently. But every single NFT gives you a passive income in USDT and tokens every minute. This is the first time in history that NFTs re not only images, but an asset. Every game prize pool is allocated across the NFT owners, which means every prediction game makes you earn passively.
                        </div>

                        <div className={style.profileNftBlock_content_createdBlock}>
                            <div className={style.profileNftBlock_content_createdBlock_left}>
                                <img src={PixieLogo} />
                            </div>
                            <div className={style.profileNftBlock_content_createdBlock_right}>
                                <span>Created by</span>
                                <span>System</span>
                            </div>
                        </div>

                        {/* <div className={style.navigation}>
                            <div className={`${style.navigation_item} ${selectedTab == Tab.Overview ? style.active : ""}`} onClick={() => setSelectedTab(Tab.Overview)}>
                                Overview
                            </div>
                            <div className={`${style.navigation_item} ${selectedTab == Tab.Statistics ? style.active : ""}`} onClick={() => setSelectedTab(Tab.Statistics)}>
                                Statistics
                            </div>
                            <div className={`${style.navigation_item} ${selectedTab == Tab.NFTsales ? style.active : ""}`} onClick={() => setSelectedTab(Tab.NFTsales)}>
                                NFT sales
                            </div>
                        </div> */}

                        {
                            selectedTab == Tab.NFTsales &&
                            <div className={style.tabContent}>

                            </div>
                        }

                        {
                            selectedTab == Tab.Overview &&
                            <div className={style.tabContent}>
                                {/* <div className={style.tabContent_item}>
                                <div className={style.tabContent_item_header}>
                                    <span>Price</span>
                                    <img src={smallInfoIcon} alt="smallInfoIcon" />
                                </div>
                                <div className={style.tabContent_item_content}>
                                    <span>$1.48M</span>
                                    <span>+62.07%</span>
                                </div>
                            </div> */}
                                <div className={style.tabContent_item}>
                                    <div className={style.tabContent_item_header}>
                                        <span>Rarity</span>
                                        <img src={smallInfoIcon} alt="smallInfoIcon" />
                                    </div>
                                    <div className={style.tabContent_item_content}>
                                        <span>{nft.rarity}</span>
                                        {/* <span>+62.07%</span> */}
                                    </div>
                                </div>
                                <div className={style.tabContent_item}>
                                    <div className={style.tabContent_item_header}>
                                        <span style={{ display: 'flex', alignItems: 'center' }}>
                                            <img src={CoinIcon} style={{ width: 13, height: 13, marginRight: 5 }} />
                                            earned
                                        </span>
                                        <img src={smallInfoIcon} alt="smallInfoIcon" />
                                    </div>
                                    <div className={style.tabContent_item_content}>
                                        <span>{(+nft.balance_total).toFixed(2)}</span>
                                        {/* <span>+62.07%</span> */}
                                    </div>
                                </div>
                                <div className={style.tabContent_item}>
                                    <div className={style.tabContent_item_header}>
                                        <span>PIX earned</span>
                                        <img src={smallInfoIcon} alt="smallInfoIcon" />
                                    </div>
                                    <div className={style.tabContent_item_content}>
                                        <span>0</span>
                                        {/* <span>+62.07%</span> */}
                                    </div>
                                </div>
                                <div className={style.tabContent_item}>
                                    <div className={style.tabContent_item_header}>
                                        <span>USDT earned</span>
                                        <img src={smallInfoIcon} alt="smallInfoIcon" />
                                    </div>
                                    <div className={style.tabContent_item_content}>
                                        <span>0</span>
                                        {/* <span>+62.07%</span> */}
                                    </div>
                                </div>
                            </div>
                        }

                        {
                            selectedTab == Tab.Statistics &&
                            <div className={style.tabContent}>

                            </div>
                        }
                    </div>
                </>
                : <PLoader />
            }
        </div>
    )
}
