export class Fund {
    constructor(
        public id: number,
        public balance_pixie_clicker: number,
        public balance_ton: number,
        public balance_usdt: number,
        public name_en: string | null,
        public name_ru: string | null,
        public percents: number,
    ) { }
}

export function mapToFund(data: any) {
    return new Fund(
        data.id,
        data.balance_pixie_clicker,
        data.balance_ton,
        data.balance_usdt,
        data.name_en,
        data.name_ru,
        data.percents
    )
}