import { mapToTask, Task } from "./task";

export class TaskProvider {
    public static async getTasks(token: string): Promise<{
        partners_tasks: Task[],
        project_tasks: Task[]
        codes_founded: number
    }> {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/v2/clicker/tasks/get/all`, {
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'auth-api-token': token
            },
        })
            .then(response => response.json())
            .then(response => response)

        const partners_tasks = (response.partners_tasks as any[]).map(mapToTask);
        const project_tasks = (response.project_tasks as any[]).map(mapToTask);
        const codes_founded = response.codes_founded;

        return {
            partners_tasks: partners_tasks,
            project_tasks: project_tasks,
            codes_founded: codes_founded,
        };
    }

    public static async checkCompleteTask(taskId: any, token: string): Promise<any> {
        let data = {
            "task_id": taskId
        };

        const response = await fetch(`${process.env.REACT_APP_API_URL}/v2/tasks/check/complete`, {
            // fetch('http://game.pixie.loc/api/clicker/tasks/check/complete', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'auth-api-token': token!
            },
            body: JSON.stringify(data)
        }).then(response => response.json())
            .then(response => response)

        return response;
    }
}