import { useEffect, useState } from 'react'
import { Card } from '../../../Components/Predict/card/card'
import otherPlaceIcon from '../../../Resources/Predict/icons/otherPlace.svg'
import secondDarkPlaceIcon from '../../../Resources/Predict/icons/secondPlace_dark.svg'
import upArrowIcon from '../../../Resources/Predict/icons/upStatArrow.svg'
import upArrowDarkIcon from '../../../Resources/Predict/icons/upStatArrow_dark.svg'
import style from './pixieFamiliesStyle.module.scss'
import { useTheme } from '../../../Context/Predict/themeContext'

interface Family {
    place: number;
    name: string;
    winRate: number;
    amount: number;
}

export function LeaderboardBlock() {
    const [families, setFamilies] = useState<Family[]>([]);
    const { theme } = useTheme();

    useEffect(() => {
        loadFamilies();
    }, [])

    function loadFamilies() {
        setFamilies([
            { place: 4, name: "Pixie Family d", winRate: 70, amount: 8000 },
            { place: 5, name: "Pixie Family d", winRate: 70, amount: 8000 },
            { place: 6, name: "Pixie Family d", winRate: 70, amount: 8000 },
            { place: 7, name: "Pixie Family d", winRate: 70, amount: 8000 },
            { place: 8, name: "Pixie Family d", winRate: 70, amount: 8000 },
            { place: 9, name: "Pixie Family d", winRate: 70, amount: 8000 }
        ])
    }

    return (
        <div className={style.leaderboardBlock}>
            <div className={style.leaderboardBlock_topFamily}>
                <div className={style.leaderboardBlock_topFamily_second}>
                    <div className={style.leaderboardBlock_topFamily_second_place}>
                        <span>2</span>
                    </div>
                    <div className={style.leaderboardBlock_topFamily_second_familyName}>Pixie Family B</div>
                    <div className={style.leaderboardBlock_topFamily_second_persent}>78%</div>
                    <div className={style.leaderboardBlock_topFamily_second_amount}>$9,500</div>
                </div>
                <div className={style.leaderboardBlock_topFamily_first}>
                    <div className={style.leaderboardBlock_topFamily_first_place}>
                        <span>1</span>
                    </div>
                    <div className={style.leaderboardBlock_topFamily_first_familyName}>Pixie Family A</div>
                    <div className={style.leaderboardBlock_topFamily_first_persent}>85%</div>
                    <div className={style.leaderboardBlock_topFamily_first_amount}>$10,000</div>
                </div>
                <div className={style.leaderboardBlock_topFamily_second}>
                    <div className={style.leaderboardBlock_topFamily_second_place}>
                        <span>3</span>
                    </div>
                    <div className={style.leaderboardBlock_topFamily_second_familyName}>Pixie Family C</div>
                    <div className={style.leaderboardBlock_topFamily_second_persent}>76%</div>
                    <div className={style.leaderboardBlock_topFamily_second_amount}>$9,200</div>
                </div>
            </div>
            <div className={style.leaderboardBlock_otherFamily}>
                {
                    families.map((family, index) =>
                        <Card key={index} className={style.leaderboardBlock_otherFamily_familyCard}>
                            <div className={style.leaderboardBlock_otherFamily_leftBlock}>
                                <div className={style.leaderboardBlock_otherFamily_leftBlock_place}>
                                    <span>{family.place}</span>
                                </div>
                                <div className={style.leaderboardBlock_otherFamily_leftBlock_info}>
                                    <div className={style.leaderboardBlock_otherFamily_leftBlock_info_title}>{family.name}</div>
                                    <div className={style.leaderboardBlock_otherFamily_leftBlock_info_subTitle}>Win Rate: {family.winRate}%</div>
                                </div>
                            </div>
                            <div className={style.leaderboardBlock_otherFamily_rightBlock}>
                                <div>${family.amount}</div>
                                <img src={theme == 'light' ? upArrowIcon : upArrowDarkIcon} alt="upArrow" />
                            </div>
                        </Card>
                    )
                }
            </div>
        </div>
    )
}
