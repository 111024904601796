import React, { PropsWithChildren } from 'react'
import style from './cardStyle.module.scss'

interface IProps {
    style?: React.CSSProperties;
    onClick?: () => void;
    className?: string;
}

export function Card(props: PropsWithChildren & IProps) {
    return (
        <div style={props.style} className={`${style.card} ${props.className}`} onClick={props.onClick}>
            {props.children}
        </div>
    )
}
