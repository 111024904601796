import { useEffect, useState } from 'react'
import style from './profileStyle.module.scss'
import CalendarIcon from '../../../Resources/Predict/icons/calendar.svg'
import CalendarDarkIcon from '../../../Resources/Predict/icons/calendar_dark.svg'
import { Card } from '../../../Components/Predict/card/card';
import { useTheme } from '../../../Context/Predict/themeContext';

enum ReferralStatus {
    Actiive = 1,
    Inactive = 2
}

interface Referral {
    referralUserName: string;
    referalDate: Date;
    status: ReferralStatus;
}

export function ProfileReferralBlock() {
    const [referrals, setReferrals] = useState<Referral[]>([]);

    const { theme } = useTheme();

    useEffect(() => {
        loadReferrals();
    }, []);

    function statusToDisplayName(status: ReferralStatus) {
        switch (status) {
            case ReferralStatus.Actiive: return 'Active';
            case ReferralStatus.Inactive: return 'Inactive';
        }
    }

    function loadReferrals() {
        setReferrals([
            { referralUserName: "Jane Smith", referalDate: new Date(), status: ReferralStatus.Actiive },
            { referralUserName: "John Doe", referalDate: new Date(), status: ReferralStatus.Actiive },
            { referralUserName: "Alice John", referalDate: new Date(), status: ReferralStatus.Actiive },
            { referralUserName: "Jane Smith", referalDate: new Date(), status: ReferralStatus.Actiive }
        ]);
    }

    function getInitials(name: string) {
        return name
            .split(' ')
            .map(part => part[0].toUpperCase())
            .join('');
    };

    function formatDate(date: Date) {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}.${month}.${day}`;
    };

    return (
        <div className={style.profileReferralBlock}>
            {
                referrals.map((referral, index) =>
                    <Card key={index} className={style.profileReferralBlock_referral}>
                        <div className={style.profileReferralBlock_referralLeft}>
                            <div className={style.profileReferralBlock_referralLeft_avatar}>
                                {getInitials(referral.referralUserName)}
                            </div>
                            <div className={style.profileReferralBlock_referralLeft_information}>
                                <div className={style.profileReferralBlock_referralLeft_information_userName}>{referral.referralUserName}</div>
                                <div className={style.profileReferralBlock_referralLeft_information_date}>
                                    Date Referred
                                </div>
                            </div>
                        </div>
                        <div className={style.profileReferralBlock_referralRight}>
                            <div className={`${style.profileReferralBlock_referralRight_status} ${referral.status === ReferralStatus.Actiive ? style.active : style.inactive}`}>
                                <div></div>
                                <span>
                                    {statusToDisplayName(referral.status)}
                                </span>
                            </div>
                            <div className={style.profileReferralBlock_referralRight_balance}>
                                <img src={theme == 'light' ? CalendarIcon : CalendarDarkIcon} alt="calendarIcon" />
                                <span>{formatDate(referral.referalDate)}</span>
                            </div>
                        </div>
                    </Card>
                )
            }
        </div>
    )
}
