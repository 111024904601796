import { PropsWithChildren, useEffect, useRef, useState } from 'react'
import downArrow from '../../../Resources/Predict/icons/downArrow1.svg'
import downArrowDark from '../../../Resources/Predict/icons/downArrow_dark.svg'
import upArrow from '../../../Resources/Predict/icons/upArrow.svg'
import upArrowDark from '../../../Resources/Predict/icons/upArrow_dark.svg'
import style from './accordionStyle.module.scss'
import { useTheme } from '../../../Context/Predict/themeContext'

interface IProps {
    title: string;
}

export function Accordion(props: IProps & PropsWithChildren) {
    const [isOpen, setIsOpen] = useState(false);
    const contentRef = useRef<HTMLDivElement>(null);
    const { theme } = useTheme();

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        if (contentRef.current) {
            contentRef.current.style.maxHeight = isOpen ? `${contentRef.current.scrollHeight}px` : '0px';
        }
    }, [isOpen]);

    return (
        <div className={style.accordion}>
            <div className={style.header} onClick={toggleAccordion}>
                <span> {props.title}</span>
                {
                    isOpen
                        ? <img src={theme == 'dark' ? upArrowDark : upArrow} alt="upArrow" />
                        : <img src={theme == 'dark' ? downArrowDark : downArrow} alt="downArrow" />
                }
            </div>
            <div
                ref={contentRef}
                className={`${style.content} ${isOpen ? style.open : ''}`}
            >
                {props.children}
            </div>
        </div>
    );
};