export class User {
    constructor(
        public id: number,
        public telegram_user_id: number,
        public first_name: string | null,
        public last_name: string | null,
        public created_at: Date,
        public username: string,
        public avatar_url: string,
        public locale: string,
        public balance: number,
        public coins_per_click: number,
        public coins_per_second: number,
        public current_coin_id: number,
        public current_energy: number,
        public current_skin_id: number,
        public drop_coins_sum: number,
        public energy_per_second: number,
        public event_clicker_count: number,
        public family_id: number,
        public improvements_data: string,
        public last_online_at: Date | null,
        public last_sync_at: Date | null,
        public level: number,
        public max_energy: number,
        public total_earn: number,
        public updated_at: Date | null,
        public user_id: number,
        public bets_count: number
    ) { }
}

export function mapToUser(data: any) {
    const created_at = new Date(data.created_at);

    const updated_at = data.updated_at != null ? new Date(data.updated_at) : null;
    const last_online_at = data.last_online_at != null ? new Date(data.last_online_at) : null;
    const last_sync_at = data.last_sync_at != null ? new Date(data.last_sync_at) : null;

    return new User(
        data.id,
        data.telegram_user_id,
        data.first_name,
        data.last_name,
        created_at,
        data.username,
        data.avatar_url,
        data.locale,
        data.balance,
        data.coins_per_click,
        data.coins_per_second,
        data.current_coin_id,
        data.current_energy,
        data.current_skin_id,
        data.drop_coins_sum,
        data.energy_per_second,
        data.event_clicker_count,
        data.family_id,
        data.improvements_data,
        last_online_at,
        last_sync_at,
        data.level,
        data.max_energy,
        data.total_earn,
        updated_at,
        data.user_id,
        data.bets_count != null ? parseFloat(data.bets_count) : 0
    );
}

