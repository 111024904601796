import '../App.css';
import { useContext, useEffect, useState } from "react";
import coinImage from "../Resources/images/coin.svg"
import Loader from "../Components/Loader";
import GameContext from "../Context/GameContext";
import { useTranslation } from "react-i18next";
import coinsImage from "../Resources/images/leaderboard-coins.svg"
import default_skin from "../Resources/images/human-free.png";
import { skins_images } from '../constants';
import { ClickerProvider } from '../Domain/Clicker/clickerProvider';
import { User } from '../Domain/User/user';

export default function Leaderboard() {
    const [leaderboardData, setLeaderboardData] = useState<User[]>([]);
    const [currentUser, setCurrentUser] = useState<User | null>(null);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [userInLeaderboard, setUserInLeaderboard] = useState<boolean>(false);

    const { userId, level } = useContext(GameContext);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        loadLeaderboard();
    }, []);

    async function loadLeaderboard() {
        const getLeaderboardResult = await ClickerProvider.getLeaderboard(userId);

        setLeaderboardData(getLeaderboardResult.users);
        setCurrentUser(getLeaderboardResult.cur_user);

        const isUserInLeaderboard = getLeaderboardResult.users.some(item => item.user_id === userId);
        setUserInLeaderboard(isUserInLeaderboard);

        setIsLoaded(true);
    }

    function nicknameFormat(first_name: string | null, last_name: string | null, username: string) {
        let nickname = "";

        const firstNameValid = first_name !== 'None' && first_name !== null;
        const lastNameValid = last_name !== 'None' && last_name !== null;

        if (firstNameValid) {
            nickname = first_name;
        }

        if (lastNameValid) {
            if (nickname !== "") {
                nickname = nickname + " " + last_name;
            } else {
                nickname = last_name;
            }
        }

        if (nickname === "") {
            nickname = username;
            if (nickname === "") {
                nickname = "Hidden username";
            }
        }

        return nickname;
    }

    if (!isLoaded) return <Loader />;

    return (
        <div className="App">
            <div className="leaderboard-container">
                <img src={coinsImage} alt="" className="leaderboard-header-image" />
                {/*<h1 className="leaderboard-container_name">{t('Leaderboard')}</h1>*/}
                <h1 className="leaderboard-container_name">{t("Level")} {level} {t('Leaderboard')}</h1>
                <div className="leaderboard-list">
                    {
                        leaderboardData.map((user, i) => (
                            <div key={i} className={"leaderboard-list_item " + (user.user_id === userId ? 'current' : '')}>
                                {/*<img src={user.avatar_url || avatarImage} alt="avatar" className="leaderboard-list_item-avatar"/>*/}
                                {/*<img src={pixieImages[user.user_id % 10]} alt="avatar" className="leaderboard-list_item-avatar"/>*/}
                                <div className="leaderboard-list_item-avatar-container">
                                    <img src={user.current_skin_id ? skins_images[user.current_skin_id - 1] : default_skin} alt="avatar" className="leaderboard-list_item-avatar" />
                                </div>
                                <div className="leaderboard-list_item-info">
                                    <span className="leaderboard-list_item-info-name">{nicknameFormat(user.first_name, user.last_name, user.username)}</span>
                                    <span className="leaderboard-list_item-info-balance">
                                        <img src={coinImage} alt="coin" className="leaderboard-list_item-info-balance-coin" />
                                        {Math.round(user.total_earn).toLocaleString('en')}
                                    </span>
                                </div>
                                <div className="leaderboard-list_item-rating">
                                    {i + 1}
                                </div>
                            </div>
                        ))
                    }
                    {
                        !userInLeaderboard && currentUser &&
                        <div className={"leaderboard-list_item fixed current"}>
                            <div className="leaderboard-list_item-avatar-container">
                                <img src={currentUser.current_skin_id ? skins_images[currentUser.current_skin_id - 1] : default_skin} alt="avatar" className="leaderboard-list_item-avatar" />
                            </div>
                            <div className="leaderboard-list_item-info">
                                <span className="leaderboard-list_item-info-name">You</span>
                                <span className="leaderboard-list_item-info-balance">
                                    <img src={coinImage} alt="coin" className="leaderboard-list_item-info-balance-coin" />
                                    {Math.round(currentUser.total_earn).toLocaleString('en')}
                                </span>
                            </div>
                            <div className="leaderboard-list_item-rating">
                                +50
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}
