export class Cryptocurrency {
    constructor(
        public price: number,
        public created_at: Date
    ) { }
}

export function mapToCryptocurrency(data: any) {
    return new Cryptocurrency(
        parseFloat(data.price),
        data.created_at,
    )
}