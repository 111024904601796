import React from 'react';
import style from './roundedTextInputStyle.module.scss';

interface IProps {
    value: string | null;
    placeholder?: string | null;
    onChange: (value: string) => void;
    color?: "light" | "dark";
    style?: React.CSSProperties;
    multiline?: boolean;
    minRows?: number;
    isError?: boolean;
}

export function RoundedTextInput(props: IProps) {
    const color = props.color ?? "light"

    const commonProps = {
        style,
        className: `${style.input} ${color == "light" ? style.light : style.dark} ${props.isError ? style.error : ""}`,
        value: props.value ?? undefined,
        onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => props.onChange(e.target.value),
        placeholder: props.placeholder ?? undefined,
    };

    return props.multiline ? (
        <textarea
            {...commonProps}
            rows={props.minRows}
        />
    ) : (
        <input
            type="text"
            {...commonProps}
        />
    );
}
