import { useTheme } from "../../../Context/Predict/themeContext";
import treeImg from "../../../Resources/Predict/images/tree.png";
import style from "./pixieFamiliesStyle.module.scss";

export function FamilyBlock() {
  const { theme } = useTheme();

  return (
    <div className={style.familyBlock}>
      <div className={style.familyBlock_statistics}>
        <div className={style.familyBlock_statisticsItem}>
          <div className={style.familyBlock_statisticsItemTitle}>Members:</div>
          <div className={style.familyBlock_statisticsItemSubTitle}>20</div>
        </div>

        <div className={style.divider}></div>

        <div className={style.familyBlock_statisticsItem} >
          <div className={style.familyBlock_statisticsItemTitle}>
            Total Earnings:
          </div>
          <div className={style.familyBlock_statisticsItemSubTitle}>
            $300.00
          </div>
        </div>
        <div className={style.divider}></div>

        <div className={style.familyBlock_statisticsItem}>
          <div className={style.familyBlock_statisticsItemTitle}>Win Rate:</div>
          <div className={style.familyBlock_statisticsItemSubTitle}>67%</div>
        </div>
      </div>
      {theme == "light" && (
        <div className={style.familyBlock_wallpaper}>
          <img src={treeImg} alt="treeImg" />
        </div>
      )}
    </div>
  );
}
