export class UserBalanceHistory {
    constructor(
        public id: number,
        public type: boolean,
        public price: number,
        public comment: string,
        public created_at: Date,
        public telegram_user_id: number,
        public game_process_id: number
    ) { }
}

export function mapToUserBalanceHistory(data: any) {
    return new UserBalanceHistory(
        data.id,
        data.type,
        data.price,
        data.comment,
        new Date(data.created_at),
        data.telegram_user_id,
        data.game_process_id,
    )
}