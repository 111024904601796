export class Improvement {
    constructor(
        public id: number,
        public name_ru: string,
        public name_en: string,
        public name_uk: string,
        public price: number,
        public give_coins: number,
        public price_coef: number,
        public give_coins_coef: number,
        public icon_number: number,
        public coins_mining_now: number | null
    ) { }
}

export function mapToImprovement(data: any) {
    return new Improvement(
        data.id,
        data.name_ru,
        data.name_en,
        data.name_uk,
        data.price,
        data.give_coins,
        data.price_coef,
        data.give_coins_coef,
        data.icon_number,
        data.coins_mining_now
    );
}