import { useContext } from 'react';
import { NavLink } from 'react-router-dom';

import menu_home_dark from '../Resources/images/menu/new/home-dark.svg';
import menu_improvements_dark from '../Resources/images/menu/new/improvements_dark.svg';
import menu_referrals_dark from '../Resources/images/menu/new/referrals_dark.svg';
import menu_socials_dark from '../Resources/images/menu/new/socials_dark.svg';
import menu_families_dark from '../Resources/images/menu/new/families_dark.svg';
import menu_predict_dark from '../Resources/images/menu/new/predict_dark.svg';
import leader_predict_dark from '../Resources/images/menu/new/leaderboard_dark.svg';
import funds_allocation_predict_dark from '../Resources/images/menu/new/funds-allocation_dark.svg';

import menu_home_light from '../Resources/images/menu/new/home_light.svg';
import menu_improvements_light from '../Resources/images/menu/new/improvements_light.svg';
import menu_referrals_light from '../Resources/images/menu/new/referrals_light.svg';
import menu_socials_light from '../Resources/images/menu/new/socials_light.svg';
import menu_families_light from '../Resources/images/menu/new/families_light.svg';
import menu_predict_light from '../Resources/images/menu/new/predict_light.svg';
import leader_predict_light from '../Resources/images/menu/new/leaderboard_light.svg';
import funds_allocation_predict_light from '../Resources/images/menu/new/funds-allocation_light.svg';

import menu_home from '../Resources/images/menu/new/home.svg';
import menu_improvements from '../Resources/images/menu/new/improvements.svg';
import menu_referrals from '../Resources/images/menu/new/referrals.svg';
import menu_socials from '../Resources/images/menu/new/socials.svg';
import menu_families from '../Resources/images/menu/families.svg';
import menu_predict from '../Resources/images/menu/new/predict.svg';
import leader_predict from '../Resources/images/menu/new/leaderboard.svg';
import GameContext from "../Context/GameContext";
import { useTheme } from '../Context/Predict/themeContext';

export default function BottomMenu() {
    const { userId } = useContext(GameContext);
    const { clickerTheme } = useTheme();

    return (
        <div className="bottom_menu">
            <div className="bottom_menu-container">
                <div>
                    <NavLink
                        to="/earns"
                        className={({ isActive }) => `bottom_menu-item tasks ${isActive ? 'active' : ''}`}
                    >
                        <img src={clickerTheme == null ? menu_socials : clickerTheme == 'dark' ? menu_socials_dark : menu_socials_light} alt="Socials" />
                    </NavLink>
                    <NavLink
                        to="/families"
                        className={({ isActive }) => `bottom_menu-item ${isActive ? 'active' : ''}`}
                    >
                        <img src={clickerTheme == null ? menu_families : clickerTheme == 'dark' ? menu_families_dark : menu_families_light} alt="Families" style={{ width: "30px" }} />
                    </NavLink>
                </div>

                <div>
                    <NavLink
                        to="/predict/laederboard"
                        className={({ isActive }) => `bottom_menu-item ${isActive ? 'active' : ''}`}
                    >
                        <img src={clickerTheme == null ? leader_predict : clickerTheme == 'dark' ? leader_predict_dark : leader_predict_light} alt="Predict" />
                    </NavLink>

                    <NavLink
                        to="/predict/funds-allocation"
                        className={({ isActive }) => `bottom_menu-item ${isActive ? 'active' : ''}`}
                    >
                        <img src={clickerTheme == 'dark' ? funds_allocation_predict_dark : funds_allocation_predict_light} alt="Predict" />
                    </NavLink>
                </div>

                <NavLink
                    to="/predict/predictions"
                    className={`bottom_menu-item active center`}
                >
                    <img src={clickerTheme == null ? menu_predict : clickerTheme == 'dark' ? menu_predict_dark : menu_predict_light} alt="Predict" />
                </NavLink>
            </div>
        </div>
    );
};