import { mapToUser, User } from "../User/user";

export class ClickerProvider {
    public static async tap(clicks_count: number, date_now_timestamp: number, token: string): Promise<any> {
        const data = {
            "clicks_count": clicks_count,
            "timestamp": date_now_timestamp
        };

        const response = await fetch(`${process.env.REACT_APP_API_URL}/v2/tap`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'auth-api-token': token
            },
            body: JSON.stringify(data)
        }).then(response => response.json())
            .then(response => response)

        return response;
    }

    public static async getLeaderboard(userId: number): Promise<{ users: User[], cur_user: User }> {
        // fetch(`https://game-api.pixie.fun/api/clicker/leaderboard/get`)
        const response = await fetch(`${process.env.REACT_APP_API_URL}/clicker/leaderboard/level/${userId}/get`)
            .then(response => response.json())
            .then(response => {
                return response
            });

        const users = (response.users as any[]).map(mapToUser)
        const cur_user = mapToUser(response.cur_user)

        return { users, cur_user }
    }
}