import React, { useEffect, useState } from 'react'
import style from './marketplaceStyle.module.scss'
import { nft } from '../profile/nfts';
import pixie1 from '../../../Resources/Predict/images/pixies/1.png'
import pixie2 from '../../../Resources/Predict/images/pixies/2.png'
import pixie3 from '../../../Resources/Predict/images/pixies/3.png'
import pixie4 from '../../../Resources/Predict/images/pixies/4.png'
import ethereumIcon from '../../../Resources/Predict/icons/Ethereum.svg'

export function MarketplacePixiesBlock() {
    const [pixies, setPixies] = useState<nft[]>([]);

    useEffect(() => {
        loadNfts();
    }, [])

    function loadNfts() {
        setPixies([
            { name: "Abstract Pink", number: "abstract #2538", price: 0.906, code: 1800, photo: pixie1 },
            { name: "Abstract Pink", number: "abstract #2538", price: 0.906, code: 1800, photo: pixie2 },
            { name: "Abstract Pink", number: "abstract #2538", price: 0.906, code: 1800, photo: pixie3 },
            { name: "Abstract Pink", number: "abstract #2538", price: 0.906, code: 1800, photo: pixie4 }
        ])
    }

    return (
        <div className={style.marketplacePixiesBlock}>
            {
                pixies.map((nft, index) =>
                    <div key={index} className={style.marketplacePixiesBlock_nftCard}>
                        <img src={nft.photo} alt={nft.name} />
                        <div className={style.marketplacePixiesBlock_nftCard_title}>
                            {nft.name}
                        </div>
                        <div className={style.marketplacePixiesBlock_nftCard_subTitle}>
                            {nft.number}
                        </div>
                        <div className={style.marketplacePixiesBlock_nftCard_priceBlock}>
                            <div className={style.marketplacePixiesBlock_nftCard_priceBlock_ethereum}>
                                <img src={ethereumIcon} alt="ethereumIcon" />
                                <span>{nft.price}</span>
                            </div>
                            <div className={style.marketplacePixiesBlock_nftCard_priceBlock_code} >
                                {nft.code}
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    )
}
