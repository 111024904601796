export class Invoice {
    constructor(
        public uuid: string | null,
        public amount: number | null,
        public api_timeout: number | null,
        public created_at: Date,
        public currency: string | null,
        public receivedf: number | null,
        public status: number,
        public status_text: string | null,
        public updated_at: Date | null,
    ) { }
}

export function mapToInvoice(data: any) {
    return new Invoice(
        data.uuid,
        data.amount,
        data.api_timeout,
        new Date(data.created_at),
        data.currency,
        data.receivedf,
        data.status,
        data.status_text,
        data.updated_at != null ? new Date(data.updated_at) : null
    )
}