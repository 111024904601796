import { ApexOptions } from 'apexcharts'
import ReactApexChart from 'react-apexcharts'
import pixVectorIcon from '../../../Resources/Predict/icons/pixVector.svg'
import pixIcon from '../../../Resources/Predict/icons/prizePool.svg'
import style from './profileStyle.module.scss'
import { Card } from '../../../Components/Predict/card/card'
import { useTheme } from '../../../Context/Predict/themeContext'

export function ProfileStatsBlock() {
    const { theme } = useTheme();

    const series = [
        {
            name: 'Data',
            data: [10, 40, 25, 50, 40, 60, 30, 10, 40, 25, 50, 40, 60, 30, 10, 40, 25, 50, 40, 60, 30],
        },
    ];

    const options: ApexOptions = {
        chart: {
            height: "100%",
            type: 'line',
            toolbar: {
                show: false,
            }
        },
        stroke: {
            curve: 'smooth',
            width: 2,
        },
        fill: {
            type: 'gradient',
            gradient: {
                shadeIntensity: 1,
                type: 'horizontal',
                shade: 'dark',
                gradientToColors: ['#ff00ff'],
                inverseColors: true,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 100],
            },
        },
        xaxis: {
            categories: ['Oct 1', 'Nov 1', 'Dec 1', 'Jan 1', 'Feb 1', 'Mar 1', 'Oct 1', 'Nov 1', 'Dec 1', 'Jan 1', 'Feb 1', 'Mar 1', 'Oct 1', 'Nov 1', 'Dec 1', 'Jan 1', 'Feb 1', 'Mar 1'],
            labels: {
                style: {
                    cssClass: style.profileStatsBlock_chart_lables
                },
            },
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
        },
        yaxis: {
            opposite: true,
            labels: {
                style: {
                    cssClass: style.profileStatsBlock_chart_lables
                },
                formatter: (val: number) => `${val}%`
            },
        },
        grid: {
            position: "back",
            borderColor: "rgba(201, 203, 205, 0.18)",
            strokeDashArray: 2.5,
            xaxis: {
                lines: {
                    show: false,
                },
            },
            // column: {
            //     colors: ["#f3f3f3", "transparent"],
            //     opacity: 0.5,
            // },
            yaxis: {
                lines: {
                    show: true,
                },
            },
        },
        dataLabels: {
            enabled: false,
        },
        tooltip: {
            enabled: true,
            shared: false,
            followCursor: false,
            intersect: false,
            inverseOrder: false,
            hideEmptySeries: true,
            fillSeriesColor: false,
            style: {
                fontSize: '12px',
            },
            onDatasetHover: {
                highlightDataSeries: false,
            },
            x: {
                show: false
            },
            y: {
                formatter: function (value) {
                    return `${formattedNumber(value)}`;
                }
            },
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                const value = series[seriesIndex][dataPointIndex];
                return `<div 
              style="padding: 5px 12px; 
              color: #1f1e36; 
              font-size: 12px; 
              border-radius: 81px; 
              background: ${theme == "light" ? "rgba(250, 250, 250, 0.63);" : "#ede0eb;"} ">${formattedNumber(value)}</div>`;
            },
            marker: {
                show: true,
            },
            fixed: {
                enabled: false,
                position: 'topRight',
                offsetX: 0,
                offsetY: 0,
            },
        },
        legend: {
            show: false,
        },
    };

    function formattedNumber(number: number) {
        return number.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
    }

    return (
        <div className={style.profileStatsBlock}>
            <Card className={style.profileStatsBlock_totalEarningsCard}>
                <div className={style.profileStatsBlock_totalEarningsCard_title} >Total Earnings:</div>
                <div className={style.profileStatsBlock_totalEarningsCard_subTitle} >$1234.56</div>

                <img className={style.profileStatsBlock_totalEarningsCard_pixVector1} src={pixVectorIcon} alt="pixVectorIcon" />
                <img className={style.profileStatsBlock_totalEarningsCard_pixVector2} src={pixVectorIcon} alt="pixVectorIcon" />

                <img className={style.profileStatsBlock_totalEarningsCard_pix1} src={pixIcon} alt="pixIcon" />
                <img className={style.profileStatsBlock_totalEarningsCard_pix2} src={pixIcon} alt="pixIcon" />
                <img className={style.profileStatsBlock_totalEarningsCard_pix3} src={pixIcon} alt="pixIcon" />
            </Card>

            <Card className={style.profileStatsBlock_chart}>
                <ReactApexChart
                    options={options} series={series}
                    type='line'
                    width='100%'
                    height='100%' />
            </Card>

            <div className={style.profileStatsBlock_earningsBreakdown}>
                <div className={style.profileStatsBlock_earningsBreakdown_title}>Earnings Breakdown:</div>
                <div className={style.profileStatsBlock_earningsBreakdown_subTitle}>Remaining Predictions Creation: <span>5</span></div>
                <div className={style.profileStatsBlock_earningsBreakdown_values}>
                    <div className={style.profileStatsBlock_earningsBreakdown_valuesItem}>
                        <div className={style.profileStatsBlock_earningsBreakdown_valuesItemTitle}
                        >
                            Pixie Family Contests:
                        </div>
                        <div className={style.profileStatsBlock_earningsBreakdown_valuesItemSubTitle}
                        >
                            $567.89
                        </div>
                    </div>
                    <div className={style.divider}></div>

                    <div className={style.profileStatsBlock_earningsBreakdown_valuesItem}>
                        <div className={style.profileStatsBlock_earningsBreakdown_valuesItemTitle}
                        >
                            Lottery:
                        </div>
                        <div className={style.profileStatsBlock_earningsBreakdown_valuesItemSubTitle}
                        >
                            $123.45
                        </div>
                    </div>

                    <div className={style.divider}></div>

                    <div className={style.profileStatsBlock_earningsBreakdown_valuesItem}>
                        <div className={style.profileStatsBlock_earningsBreakdown_valuesItemTitle}
                        >
                            NFTs:
                        </div>
                        <div className={style.profileStatsBlock_earningsBreakdown_valuesItemSubTitle}
                        >
                            $300.00
                        </div>
                    </div>
                    <div className={style.divider}></div>

                    <div className={style.profileStatsBlock_earningsBreakdown_valuesItem}>
                        <div className={style.profileStatsBlock_earningsBreakdown_valuesItemTitle}
                        >
                            Referrals:
                        </div>
                        <div className={style.profileStatsBlock_earningsBreakdown_valuesItemSubTitle}
                        >
                            $243.22
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
