import React, { useState, useEffect } from 'react';
import style from './datepPicker.module.scss'
import FlatCalendar from '../../../../Resources/Predict/icons/flat_calendar.svg'
import FlatDarkCalendar from '../../../../Resources/Predict/icons/flat_calendar_dark.svg'
import { deepStrictEqual } from 'assert';

interface IProps {
    value?: Date | null;
    onChange: (value: Date | null) => void;
    theme?: 'light' | 'dark';
    placeholder?: string;
    isError?: boolean;
}

export default function DatePicker(props: IProps) {
    const [inputValue, setInputValue] = useState<string>('');
    const theme = props.theme ?? "light"

    useEffect(() => {
        if (props.value) {
            const day = String(props.value.getDate()).padStart(2, '0');
            const month = String(props.value.getMonth() + 1).padStart(2, '0');
            const year = props.value.getFullYear();
            setInputValue(`${day}.${month}.${year}`);
        }
    }, [props.value]);

    const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const rawValue = e.target.value.replace(/\D/g, '');
        let formattedDate = rawValue.slice(0, 8);

        if (formattedDate.length >= 5) {
            formattedDate = `${formattedDate.slice(0, 2)}.${formattedDate.slice(2, 4)}.${formattedDate.slice(4)}`;
        } else if (formattedDate.length >= 3) {
            formattedDate = `${formattedDate.slice(0, 2)}.${formattedDate.slice(2)}`;
        }

        setInputValue(formattedDate);

        if (formattedDate.length === 10) {
            const [day, month, year] = formattedDate.split('.');
            const date = new Date(Number(year), Number(month) - 1, Number(day));

            if (date.getFullYear() === Number(year) && date.getMonth() === Number(month) - 1 && date.getDate() === Number(day)) {
                props.onChange(date);
            } else {
                props.onChange(null);
            }
        } else {
            props.onChange(null);
        }
    };

    return (
        <div className={`${style.datePickerContainer} ${theme == 'light' ? style.light : style.dark}`}>
            <input
                type="tel"
                value={inputValue}
                onChange={handleDateChange}
                placeholder={props.placeholder}
                className={`${style.input} ${props.isError ? style.error : ""}`}
                maxLength={10}
                inputMode="numeric"
            />
            <img src={theme == 'light' ? FlatCalendar : FlatDarkCalendar} alt="FlatCalendar" />
        </div>
    );
}
