import { AnyARecord } from "dns";

export class Skin {
    constructor(
        public id: number,
        public image_id: number,
        public price: number,
        public active: boolean,
        public earning_boost: number,
        public energy_bar_boost: number,
        public per_tap_boost: number,
        public user_id: number,
        public skin_id: number
    ) { }
}

export function mapToSkin(data: any) {
    return new Skin(
        data.id,
        data.image_id,
        data.price,
        data.active,
        data.earning_boost,
        data.energy_bar_boost,
        data.per_tap_boost,
        data.user_id,
        data.skin_id,
    )
}