export class EventProvider {
    public static async getDropCoinsResultList(token: string) {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/v3/event/dropcoins/results/list`, {
            method: 'GET',
            headers: {
                'auth-api-token': token!
            }
        })
            .then(response => response.json())
            .then(response => response);

        return response;
    }

    public static async setDropCoins(coinCount: number, token: string) {
        let data = {
            "score": coinCount
        };

        const response = await fetch(`${process.env.REACT_APP_API_URL}/v3/event/dropcoins/set`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'auth-api-token': token!
            },
            body: JSON.stringify(data)
        }).then(response => response.json())
            .then(response => response)

        return response;
    }
}