export class Award {
    constructor(
        public coins: number,
        public event_id: number,
        public received: boolean,
        public user_id: number,
        public uuid: string
    ) { }
}

export function mapToAward(data: any) {
    return new Award(
        data.coins,
        data.event_id,
        data.received,
        data.user_id,
        data.uuid,
    )
}