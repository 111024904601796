export class InvoicePaymentInfo {
    constructor(
        public uuid: string,
        public amount: number,
        public api_address: string,
        public api_qrcode_url: string,
        public currency: string | null,
        public id: number | null,
        public receivedf: number | null,
        public status: number,
        public status_text: string,
        public user_id: number
    ) { }
}

export function mapToInvoicePaymentInfo(data: any) {
    return new InvoicePaymentInfo(
        data.uuid,
        data.amount,
        data.api_address,
        data.api_qrcode_url,
        data.currency,
        data.id,
        data.receivedf,
        data.status,
        data.status_text,
        data.user_id
    )
}