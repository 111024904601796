import React, { useContext, useEffect, useState } from 'react';
import '../Resources/styles/loader.css'
import GameContext from '../Context/GameContext';

export default function Loader() {
    const [loadingText, setLoadingText] = useState('Loading');
    const { userId } = useContext(GameContext);

    useEffect(() => {
        let counterInterval = 0;

        let interval = setInterval(() => {
            counterInterval++;

            if (counterInterval === 1) {
                setLoadingText("Loading.")
            } else if (counterInterval === 2) {
                setLoadingText("Loading..")
            } else if (counterInterval === 3) {
                setLoadingText("Loading...")
            } else {
                setLoadingText("Loading")
                counterInterval = 0;
            }
        }, 500);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="loader_container">
            <div className="loader"></div>
            <span className="loader_text">{loadingText}</span>
        </div>
    );
};