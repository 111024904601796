export class Coin {
    constructor(
        public id: number,
        public coin_id: number,
        public name_ru: string,
        public name_en: string,
        public name_uk: string,
        public image_id: number,
        public price: number,
        public active: boolean,
        public shadow_color: string,
        public per_tap_boost: number,
        public energy_bar_boost: number,
        public earning_boost: number,
        public user_id: number
    ) { }
}

export function mapToCoin(data: any) {
    return new Coin(
        data.id,
        data.coin_id,
        data.name_ru,
        data.name_en,
        data.name_uk,
        data.image_id,
        data.price,
        data.active,
        data.shadow_color,
        data.per_tap_boost,
        data.energy_bar_boost,
        data.earning_boost,
        data.user_id,
    );
}

