import { useParams } from "react-router-dom";
import { FastPredictionPage } from './fast/fastPredictionPage';
import { CustomPredictionPage } from './custom/customPrediction';
import { SomePredictionVariantsPage } from "./someVariants/someVariants";
import TrumpImage from '../../../Resources/Predict/images/predicts/trump.jpg'
import KamalaHarrisImage from '../../../Resources/Predict/images/predicts/kamala-harris.jpg'
import LiquidImage from '../../../Resources/Predict/images/predicts/Liquid.jpg'
import C9Image from '../../../Resources/Predict/images/predicts/C9.jpg'
import TundraImage from '../../../Resources/Predict/images/predicts/Tundra.jpg'
import Gaimin_GladiatorsImage from '../../../Resources/Predict/images/predicts/Gaimin_Gladiators.jpg'
import { FillingGlass } from "./fillingGlass/fillingGlass";

export function PredictionPage() {
    let { id } = useParams();
    const numericId = id ? parseInt(id, 10) : null;

    const fastPredictionIds = [1, 3, 56, 59, 60, 61, 69, 89, 90, 91, 92, 95, 96]

    function getPage(id: number) {
        if (fastPredictionIds.includes(id)) return <FastPredictionPage />;
        if (id == 4) return <SomePredictionVariantsPage
            trueVariant={{
                title: "Donald Trump",
                image: TrumpImage
            }}
            falseVariant={{
                title: "Kamala Harris",
                image: KamalaHarrisImage
            }}
        />


        if (id == 33) return <SomePredictionVariantsPage
            trueVariant={{
                title: "Liquid",
                image: LiquidImage
            }}
            falseVariant={{
                title: "Cloud9",
                image: C9Image
            }}
        />

        if (id == 34) return <SomePredictionVariantsPage
            trueVariant={{
                title: "Tundra",
                image: TundraImage
            }}
            falseVariant={{
                title: "Gaimin Gladiators",
                image: Gaimin_GladiatorsImage
            }}
        />

        if (id == 97) return <FillingGlass />

        return <SomePredictionVariantsPage
            trueVariant={{
                title: "Yes",
                image: null
            }}
            falseVariant={{
                title: "No",
                image: null
            }}
        />
    }

    return (
        <>
            {
                numericId !== null
                    ? <div>{getPage(numericId)}</div>
                    : <div>Not Found</div>
            }
        </>
    )
}
