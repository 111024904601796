import React, { useEffect, useState } from 'react'
import style from './toggleSwitchStyle.module.scss'

interface IProps {
    value: boolean;
    onChange: (value: boolean) => void;
}

export function ToggleSwitch(props: IProps) {
    const [checked, setChecked] = useState<boolean>(props.value);

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        const checked = event.target.checked;
        setChecked(checked);
    };

    useEffect(() => {
        props.onChange(checked);
    }, [checked])

    return (
        <label className={style.switch}>
            <input type="checkbox" checked={checked} onChange={handleChange} />
            <span className={style.slider}></span>
        </label>
    )
}
