import React, { useState } from 'react'
import style from './profileStyle.module.scss'
import { Card } from '../../../Components/Predict/card/card'
import UsdtIcon from '../../../Resources/Predict/icons/USDT.svg'
import PixIcon from '../../../Resources/Predict/icons/PIX.svg'
import FunPixIcon from '../../../Resources/Predict/icons/FunPIX.svg'
import CreditCardIcon from '../../../Resources/Predict/icons/CreditCard.svg'
import MetamaskIcon from '../../../Resources/Predict/icons/Metamask.svg'

enum WithdrawalMethods {
    USDT = 1,
    Metamask = 2,
    CreditCard = 3
}

export function ProfileBalanceBlock() {
    const [selectedWithdrawalMethod, setSelectedWithdrawalMethod] = useState<WithdrawalMethods | null>(null);

    return (
        <div className={style.profileBalanceBlock}>
            <Card>
                <div className={style.profileBalanceBlock_balanceAccounts}>
                    <div className={style.profileBalanceBlock_balanceAccountsItem}>
                        <div className={style.profileBalanceBlock_balanceAccountsItem_accountName}>
                            <img src={UsdtIcon} alt="UsdtIcon" />
                            <span>USDT: </span>
                        </div>
                        <div className={style.profileBalanceBlock_balanceAccountsItem_accountValue}>$123.45</div>
                    </div>

                    <div className={style.profileBalanceBlock_balanceAccounts_divider} />

                    <div className={style.profileBalanceBlock_balanceAccountsItem}>
                        <div className={style.profileBalanceBlock_balanceAccountsItem_accountName}>
                            <img src={PixIcon} alt="PixIcon" />
                            <span>PIX:</span>
                        </div>
                        <div className={style.profileBalanceBlock_balanceAccountsItem_accountValue}>567.89 PIX</div>
                    </div>

                    <div className={style.profileBalanceBlock_balanceAccounts_divider} />

                    <div className={style.profileBalanceBlock_balanceAccountsItem}>
                        <div className={style.profileBalanceBlock_balanceAccountsItem_accountName}>
                            <img src={FunPixIcon} alt="FunPixIcon" />
                            <span>FunPIX: </span>
                        </div>
                        <div className={style.profileBalanceBlock_balanceAccountsItem_accountValue}>1000 FunPIX</div>
                    </div>
                </div>
            </Card>

            <div className={style.profileBalanceBlock_withdrawButton}>
                Withdraw
            </div>

            <div className={style.profileBalanceBlock_withdrawalMethods_title}>
                Deposit Options:
            </div>

            <div className={style.profileBalanceBlock_withdrawalMethods}>
                <Card onClick={() => setSelectedWithdrawalMethod(WithdrawalMethods.USDT)}
                    className={`${style.profileBalanceBlock_withdrawalMethods_item} ${selectedWithdrawalMethod == WithdrawalMethods.USDT ? style.active : ""}`}>
                    <img src={UsdtIcon} alt="UsdtIcon" />
                    <span>USDT Wallet</span>
                </Card>
                <Card onClick={() => setSelectedWithdrawalMethod(WithdrawalMethods.Metamask)}
                    className={`${style.profileBalanceBlock_withdrawalMethods_item} ${selectedWithdrawalMethod == WithdrawalMethods.Metamask ? style.active : ""}`}
                >
                    <img src={MetamaskIcon} alt="MetamaskIcon" />
                    <span>Metamask</span>
                </Card>
                <Card onClick={() => setSelectedWithdrawalMethod(WithdrawalMethods.CreditCard)}
                    className={`${style.profileBalanceBlock_withdrawalMethods_item} ${selectedWithdrawalMethod == WithdrawalMethods.CreditCard ? style.active : ""}`}>
                    <img src={CreditCardIcon} alt="CreditCardIcon" />
                    <span>Credit Card</span>
                </Card>
            </div>
        </div>
    )
}
