export class CryptocurrencyAll {
    constructor(
        public btc_pricew: number,
        public dogs_price: number,
        public eth_price: number,
        public hmstr_price: number,
        public not_price: number,
        public ton_price: number,
        public shib_price: number,
        public pepe_price: number,
        public doge_price: number,
    ) { }
}

export function mapToCryptocurrencyAll(data: any) {
    return new CryptocurrencyAll(
        data.btc_pricew,
        data.dogs_price,
        data.eth_price,
        data.hmstr_price,
        data.not_price,
        data.ton_price,
        data.shib_price,
        data.pepe_price,
        data.doge_price,
    )
}