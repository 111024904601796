import { useNavigate } from 'react-router-dom';
import style from './style.module.scss';
import { useContext, useMemo, useState } from 'react';
import GameContext from '../../../../Context/GameContext';
import { useTheme } from '../../../../Context/Predict/themeContext';
import CoinIcon from '../../../../Resources/Predict/images/coin.svg';
import СoinBrownIcon from '../../../../Resources/Predict/icons/coin_brown.svg';
import DownArrowIcon from '../../../../Resources/Predict/icons/downArrow1.svg';
import DownArrowDarkIcon from '../../../../Resources/Predict/icons/downArrow_dark.svg';
import UsdtIcon from '../../../../Resources/Predict/icons/USDT.svg';
import TonIcon from '../../../../Resources/Predict/icons/ton.svg';
import { Currency } from '../../../../Providers/GameProvider';

export default function BalanceCard() {
    const { score, dao_balance, updateGame, balance_usdt } = useContext(GameContext);
    const predictBalance = useMemo(() => parseFloat((score / 1_000_000).toFixed(2)), [score])

    const navigate = useNavigate();
    const [menuOpen, setMenuOpen] = useState(false);

    function toggleMenu(e: any) {
        e.stopPropagation();

        setMenuOpen(!menuOpen);
    };

    function handleMenuItemClick(e: any, currency: Currency) {
        e.stopPropagation();

        updateGame({ selectedCurrency: currency })
        setMenuOpen(false);
    };

    function formatNumber(value: number): string {
        return Number.isInteger(value) ? value.toString() : value.toFixed(2);
    }

    return (
        <div className={style.header_balance} onClick={() => navigate("/predict/profile/history/games")}>
            <div>
                <img src={CoinIcon} alt="СoinIcon" />
                <div className={style.header_balance_title}>
                    {predictBalance.toLocaleString("en")}
                </div>
            </div>
            <div>
                <img src={СoinBrownIcon} alt="СoinIcon" />
                <span className={style.header_balance_title}>
                    {(+dao_balance.toFixed(2)).toLocaleString("en")}
                </span>
                <img
                    src={DownArrowDarkIcon}
                    className={style.arrow_icon}
                    onClick={toggleMenu}
                />
                {
                    menuOpen && (
                        <div className={style.dropdown_menu}>
                            <div onClick={(e) => handleMenuItemClick(e, Currency.Ton)}>
                                <img src={TonIcon} />
                                <span>Ton: 0</span>
                            </div>
                            <div onClick={(e) => handleMenuItemClick(e, Currency.Usdt)}>
                                <img src={UsdtIcon} />
                                <span>Usdt: {formatNumber(+balance_usdt)}</span>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    )
}
