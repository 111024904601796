import '../App.css';
import { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import coinImage from "../Resources/images/coin.svg";
import GameContext from "../Context/GameContext";
import Loader from "../Components/Loader";
import { useTranslation } from "react-i18next";
import energyIcon from "../Resources/images/energy_icon.svg";
import { coins_images } from '../constants';
import { CoinsProvider } from '../Domain/Coins/coinsProvider';
import { Coin } from '../Domain/Coins/coint';
import WebApp from '@twa-dev/sdk';
import { useTheme } from '../Context/Predict/themeContext';

export default function Coins() {
    const { score, updateGame, token, userId, coinId } = useContext(GameContext);

    const [coins, setCoins] = useState<Coin[]>([]);
    const [currentCoin, setCurrentCoin] = useState<Coin | null>(null);

    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [viewPopup, setViewPopup] = useState<boolean>(false);

    const { t, i18n } = useTranslation();

    const { setClickerTheme } = useTheme();

    useEffect(() => {
        laodCoins();
    }, []);

    async function laodCoins() {
        const responseCoins = await CoinsProvider.getAll(userId);
        setCoins(responseCoins);

        setIsLoaded(true);
    }

    function translatedName(coin: Coin) {
        if (i18n.language === 'ru') {
            return coin.name_ru;
        } else if (i18n.language === 'uk') {
            return coin.name_uk;
        } else {
            return coin.name_en;
        }
    }

    function clickCoinCard(coin: Coin) {
        if (coin.user_id !== null) return;

        setCurrentCoin(coin);
        setViewPopup(true);
    }

    function closePopup() {
        setViewPopup(false);
        setCurrentCoin(null);
    }

    async function buyCoin(coinId: number) {
        if (currentCoin == null) return;
        if (score < currentCoin.price) return;

        let date_now_obj = new Date();
        let date_now_timestamp = date_now_obj.getTime();
        date_now_timestamp = date_now_timestamp / 1000;
        date_now_timestamp = date_now_timestamp;

        setIsLoaded(false);

        const response = await CoinsProvider.buy(coinId, date_now_timestamp, token!);
        if (response.message === "ok") {
            setViewPopup(false);
            setCurrentCoin(null);

            laodCoins();
        }

        updateGame({
            score: parseFloat(response.balance)
        })
    }

    async function changeCoin(coin: Coin) {
        let date_now_obj = new Date();
        let date_now_timestamp = date_now_obj.getTime();
        date_now_timestamp = date_now_timestamp / 1000;
        date_now_timestamp = date_now_timestamp;

        const response = await CoinsProvider.set(coin.id, date_now_timestamp, token!);
        if (response.message === "ok") {
            if (coin.id === 10) {
                // document.body.classList.add('golden-pixie-coin-event');
            } else {
                // document.body.classList.remove('golden-pixie-coin-event');
            }

            updateGame({
                coinId: coin.id,
                coinImageId: coin.image_id,
                coinShadowColor: coin.shadow_color,
            });
        }
        updateGame({
            score: parseFloat(response.balance)
        })
    }

    if (!isLoaded) return <Loader />;

    return (
        <div className="App">
            <div className="improve_container">
                {/*<h1 className="improve_container-name">{t("improvements")}</h1>*/}
                <div className="improvements_header-buttons">
                    <NavLink to="/improve" className={({ isActive }) => `improvements_header-buttons-button ${isActive ? 'active' : ''}`} >
                        {t('improvements')}
                    </NavLink>
                    <NavLink to="/coins" className={({ isActive }) => `improvements_header-buttons-button ${isActive ? 'active' : ''}`} >
                        {t('coins')}
                    </NavLink>
                    <NavLink to="/skins" className={({ isActive }) => `improvements_header-buttons-button ${isActive ? 'active' : ''}`} >
                        {t('skins')}
                    </NavLink>
                </div>

                {
                    viewPopup && currentCoin &&
                    <div className="offline_profit_container coins">
                        <div className="offline_profit_container-content tasks coins">
                            <button className="offline_profit_container-content-button-close" onClick={() => { closePopup() }}>✕</button>
                            <span className="offline_profit_container-content-text">{translatedName(currentCoin)}</span>
                            <img src={coins_images[currentCoin.image_id]} alt="" />

                            <div className="coins_container-row-item-boosts-popup">
                                <div className="coins_container-row-item-boosts-item">
                                    <img src={coinImage} alt="" />
                                    Boost per tap
                                    +{currentCoin.per_tap_boost}
                                </div>
                                <div className="coins_container-row-item-boosts-item">
                                    <img src={energyIcon} alt="" />
                                    Energy bar boost
                                    +{currentCoin.energy_bar_boost}%
                                </div>
                                <div className="coins_container-row-item-boosts-item">
                                    <img src={coinImage} alt="" />
                                    Per hour boost
                                    +{currentCoin.earning_boost}%
                                </div>
                            </div>

                            <span className="offline_profit_container-content-value">
                                <img src={coinImage} alt="" />
                                {currentCoin.price.toLocaleString()}
                            </span>
                            <div className="popup_tasks_buttons-bottom">
                                <div className="popup_tasks_buttons">
                                    <button className="popup_tasks_buttons-button" onClick={() => closePopup()}>{t('Close')}</button>
                                    <button className={"popup_tasks_buttons-button second " + (score < currentCoin.price ? 'disabled' : '')} onClick={() => { buyCoin(currentCoin.id) }}>{t('Buy')}</button>
                                </div>
                            </div>
                        </div>
                        <div className="offline_profit_container-overlay" onClick={() => { closePopup() }}></div>
                    </div>
                }

                <div className="coins_container-row">
                    {
                        coins.map((coin, index) => (
                            <div key={index} className={"coins_container-row-item " + (coin.user_id ? 'buyed' : '')} onClick={() => clickCoinCard(coin)}>
                                <div className="coins_container-row-item-boosts">
                                    <div className="coins_container-row-item-boosts-item">
                                        <img src={coinImage} alt="" />
                                        +{coin.per_tap_boost}
                                    </div>
                                    <div className="coins_container-row-item-boosts-item">
                                        <img src={energyIcon} alt="" />
                                        +{coin.energy_bar_boost}%
                                    </div>
                                    <div className="coins_container-row-item-boosts-item">
                                        <img src={coinImage} alt="" />
                                        +{coin.earning_boost}%
                                    </div>
                                </div>
                                <img src={coins_images[coin.image_id]} alt="" />
                                <span className="coins_container-row-item-name">{translatedName(coin)}</span>
                                <span className="coins_container-row-item-price">
                                    {
                                        coin.user_id
                                            ? coin.coin_id === coinId
                                                ? t('selected')
                                                : <button className="coins-button-choose" onClick={() => changeCoin(coin)}>{t('choose')}</button>
                                            : <><img src={coinImage} alt="" />{(coin.price).toLocaleString('en')}</>
                                    }
                                </span>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}