import { FillingGlassGameHistoryPlayer, mapToFillingGlassGameHistoryPlayer } from "./fillingGlassGameHistoryPlayer";

export class FillingGlassGameHistory {
    constructor(
        public id: number,
        public total: number,
        public players: FillingGlassGameHistoryPlayer[]
    ) { }
}

export function mapToFillingGlassGameHistory(data: any) {
    return new FillingGlassGameHistory(
        data.game_process_id,
        data.prize_pool,
        (data.players as any[]).map(mapToFillingGlassGameHistoryPlayer)
    )
}