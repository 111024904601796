export class UserGameHistory {
    constructor(
        public game_id: number,
        public game_process_id: number,
        public game_name: string,
        public balance_has: number | null,
        public balance_was: number | null,
        public coef_down: number | null,
        public coef_up: number | null,
        public created_at: Date,
        public price_bet: number,
        public price_last: number | null,
        public price_locked: number | null,
        public type_bet: boolean | null,
        public won_money: number | null,
    ) { }
}

export function mapToUserGameHistory(data: any) {
    return new UserGameHistory(
        data.game_id,
        data.game_process_id,
        data.name,
        data.balance_has != null ? parseFloat(data.balance_has) : null,
        data.balance_was != null ? parseFloat(data.balance_was) : null,
        data.coef_down != null ? parseFloat(data.coef_down) : null,
        data.coef_up != null ? parseFloat(data.coef_up) : null,
        new Date(data.created_at + "Z"),
        parseFloat(data.price_bet),
        data.price_last != null ? parseFloat(data.price_last) : null,
        data.price_locked != null ? parseFloat(data.price_locked) : null,
        data.type_bet,
        data.won_money != null ? parseFloat(data.won_money) : null,
    )
}