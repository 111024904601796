import React from 'react'
import style from './marketplaceStyle.module.scss'
import { NavLink, Outlet } from 'react-router-dom'
import { Card } from '../../../Components/Predict/card/card'
import { HorizontalNavigation } from '../../../Components/Predict/navigation/horizontalNavigation'

export function MarketplacePage() {
    return (
        <div className={style.marketplaceContainer}>
            <HorizontalNavigation
                navItems={[
                    { title: "NFTs", to: "/marketplace/nfts" },
                    { title: "Pixies", to: "/marketplace/pixies" },
                    { title: "Skins Upgrade", to: "/marketplace/skins-upgrade" }
                ]}
            />
            <Card className={style.marketplaceContainer_welcomeBox}>
                <div className={style.marketplaceContainer_welcomeBox_title}>Welcome to the Marketplace </div>
                <div className={style.marketplaceContainer_welcomeBox_subTitle}>Browse through our collection <br /> of NFTs, Pixies, and skins. </div>
            </Card>

            <div className={style.marketplaceContainer_content}>
                <Outlet />
            </div>
        </div>
    )
}
