export class ActiveGame {
    constructor(
        public game_id: number,
        public type_bet: boolean,
        public price_bet: number,
        public bet_datetime_at: Date,
        public coef_up: number | null,
        public coef_down: number | null,
        public name: string,
        public image: string,
        public button_name_up: string,
        public button_name_down: string,
    ) { }
}

export function mapToActiveGame(data: any) {
    return new ActiveGame(
        data.game_id,
        data.type_bet,
        data.price_bet,
        new Date(data.bet_datetime_at),
        data.coef_up == null ? null : parseFloat(data.coef_up),
        data.coef_down == null ? null : parseFloat(data.coef_down),
        data.name,
        data.image,
        data.button_name_up,
        data.button_name_down,
    );
}