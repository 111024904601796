import '../App.css';
import { useContext, useEffect, useState } from "react";
import coinImage from "../Resources/images/coin.svg";
import GameContext from "../Context/GameContext";
import Loader from "../Components/Loader";
import { useTranslation } from "react-i18next";
import telegramIcon from "../Resources/images/tasks/telegram.svg";
import telegramDarkIcon from "../Resources/images/tasks/telegram_dark.svg";
import telegramLightIcon from "../Resources/images/tasks/telegram_light.svg";
import xIcon from "../Resources/images/x-twitter.svg";
import iconArrow from "../Resources/images/tasks/arrow.svg";
import present_image from "../Resources/images/present.svg";
import present_image_dark from "../Resources/images/present_dark.svg";
import present_image_light from "../Resources/images/present_light.svg";
import gorgon_icon from "../Resources/images/gorgon.jpg";
import hexn_icon from "../Resources/images/tasks/hexn.png";
import cryptowolf_icon from "../Resources/images/tasks/cryptowolf.jpg";
import tonchurch_icon from "../Resources/images/tasks/tonchurch.svg";
import doomsday_icon from "../Resources/images/tasks/doomsday_icon.png";
import drophunter_icon from "../Resources/images/tasks/drophunter.jpg";
import topcoin_icon from "../Resources/images/tasks/topcoin.png";
import tapcoins_icon from "../Resources/images/tasks/tapcoins.png";
import Tapula_icon from "../Resources/images/tasks/Tapula.png";
import Bull_mascot_icon from "../Resources/images/tasks/Bull_mascot.jpg";
import Gemsee_icon from "../Resources/images/tasks/Gemsee.jpg";
import Task52_icon from "../Resources/images/tasks/task52.png";
import PandaBear_icon from "../Resources/images/tasks/Panda-bear.svg";
import corn_icon from "../Resources/images/tasks/corn_icon.png";
import { CodesProvider } from '../Domain/Codes/codesProvider';
import { TaskProvider } from '../Domain/Task/taskProvider';
import { Task } from '../Domain/Task/task';
import potatoNFT_icon from "../Resources/images/tasks/PotatoNFT.jpg";
import SenerCrypto_icon from "../Resources/images/tasks/SenerCrypto.png";
import DoomerStory_icon from "../Resources/images/tasks/DoomerStory.png";
import { useTheme } from '../Context/Predict/themeContext';

export default function Earns() {
    const { updateGame, userId, token } = useContext(GameContext);

    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    const [tasksPartners, setTasksPartners] = useState<Task[]>([]);
    const [tasksProject, setTasksProject] = useState<Task[]>([]);
    const [currentTask, setCurrentTask] = useState<Task | null>(null);

    const [viewPopup, setViewPopup] = useState<boolean>(false);
    const [taskNotCompleted, setTaskNotCompleted] = useState<boolean>(false);
    const [shareText, setShareText] = useState<string>("");
    const [codesFound, setCodesFound] = useState<number>(0);
    const [codeValue, setCodeValue] = useState<string>("");
    const [codeErrorText, setCodeErrorText] = useState<string>("");
    const [codeSuccessText, setCodeSuccessText] = useState<string>("");

    const { t, i18n } = useTranslation();

    const { clickerTheme } = useTheme();

    useEffect(() => {
        loadShareText();
        loadTaks();
    }, []);

    function loadShareText() {
        // if(i18n.language === 'ru'){
        //     setShareText("Играй со мной, стань босом студии и получай токены через аирдроп!%0A\uD83D\uDCB8 +20k монет за вход%0A\uD83D\uDD25 +50k монет, если у тебя есть Telegram Premium")
        // }else if(i18n.language === 'uk'){
        //     setShareText("Грай зі мною, стань босом студії та отримай токени через ейрдроп!%0A\uD83D\uDCB8 +20k монет у якості подарунка%0A\uD83D\uDD25 +50k монет, якщо у тебе Telegram Premium")
        // }else{
        //     setShareText("Play with be, become a studio boss and get tokens via airdrop! %0A\uD83D\uDCB8 +20k coins as a gift%0A\uD83D\uDD25 +50k coins, if you have Telegram Premium")
        // }
        if (i18n.language === 'ru') {
            setShareText("Играй со мной, стань босом студии и получай токены через аирдроп!%0A\uD83D\uDCB8 +1.000.000 монет за вход")
        } else if (i18n.language === 'uk') {
            setShareText("Грай зі мною, стань босом студії та отримай токени через ейрдроп!%0A\uD83D\uDCB8 +1.000.000 монет у якості подарунка")
        } else {
            setShareText("Play with me, become a studio boss and get tokens via airdrop! %0A\uD83D\uDCB8 +1.000.000 coins as a gift")
        }
    }

    async function loadTaks() {
        setIsLoaded(false);

        const getTaskResult = await TaskProvider.getTasks(token!);
        setTasksPartners(getTaskResult.partners_tasks);
        setTasksProject(getTaskResult.project_tasks);
        setCodesFound(getTaskResult.codes_founded);

        setIsLoaded(true);
    }

    async function checkCode() {
        if (codeValue == "") return;

        const response = await CodesProvider.checkCode(codeValue, token!);
        if (response.message !== 'ok') {
            setCodeSuccessText("");
            if (i18n.language === 'ru') {
                setCodeErrorText("Код не найден или уже использован!");
            } else if (i18n.language === 'uk') {
                setCodeErrorText("Код не знайдено або вже використано!");
            } else {
                setCodeErrorText("Code not found or already used!");
            }

            setTimeout(() => {
                setCodeErrorText("");
            }, 3000);
        }
        else {
            setCodesFound(codesFound + 1);

            setCodeErrorText("");
            if (i18n.language === 'ru') {
                setCodeSuccessText(`Код успешно применен (+${(response.coins).toLocaleString('en')} монет)`);
            } else if (i18n.language === 'uk') {
                setCodeSuccessText(`Код успішно застосований (+${(response.coins).toLocaleString('en')} coins)`);
            } else {
                setCodeSuccessText(`Code successfully applied (+${(response.coins).toLocaleString('en')} coins)`);
            }

            setCodeValue("");

            updateGame({
                score: parseFloat(response.balance)
            });

            setTimeout(() => {
                setCodeSuccessText("");
            }, 3000);
        }
    }

    function translatedName(task: Task) {
        if (i18n.language === 'ru') {
            return task.name_ru;
        } else if (i18n.language === 'uk') {
            return task.name_uk;
        } else {
            return task.name_en;
        }
    }

    function taskMore(task: Task) {
        if ([10, 11, 12].includes(task.id)) {
            if (task.was_completed) return;
        } else {
            if (task.task_id) return;
        }

        setCurrentTask(task);
        setViewPopup(true);
    }

    function locateToLink(link: string | null) {
        if (link == null) return;

        return window.location.href = link;
    }

    async function checkRules(task: Task) {
        const completeTaskResponse = await TaskProvider.checkCompleteTask(task.id, token!);
        if (completeTaskResponse.message === 'ok') {
            updateGame({
                score: parseFloat(completeTaskResponse.balance)
            });

            setViewPopup(false);
            setCurrentTask(null);
            setTasksPartners([]);
            setTasksProject([]);

            loadTaks();
        } else {
            setTaskNotCompleted(true);
            setTimeout(() => {
                setTaskNotCompleted(false);
            }, 3000);
        }
    }

    function closePopupTasks() {
        setViewPopup(false);
        setCurrentTask(null);
        setTaskNotCompleted(false);
    }

    function taskImage(id: number) {
        if (id === 8) {
            return <img src={xIcon} alt="" className="tasks_container-item-image" />
        } else if (id === 13) {
            return <img src={gorgon_icon} alt="" className="tasks_container-item-image" />
        } else if (id === 14) {
            return <img src={hexn_icon} alt="" className="tasks_container-item-image" />
        } else if (id === 15) {
            return <img src={coinImage} alt="" className="tasks_container-item-image" />
        } else if (id === 16) {
            return <img src={cryptowolf_icon} alt="" className="tasks_container-item-image" />
        } else if (id === 17) {
            return <img src={drophunter_icon} alt="" className="tasks_container-item-image" />
        } else if (id === 18) {
            return <img src={doomsday_icon} alt="" className="tasks_container-item-image" />
        } else if (id === 19) {
            return <img src={tonchurch_icon} alt="" className="tasks_container-item-image" />
        } else if (id === 20) {
            return <img src={topcoin_icon} alt="" className="tasks_container-item-image" />
        } else if (id === 31) {
            return <img src={tapcoins_icon} alt="" className="tasks_container-item-image" />
        } else if (id === 32) {
            return <img src={tapcoins_icon} alt="" className="tasks_container-item-image" />
        } else if (id == 39) {
            return <img src={corn_icon} alt="" className="tasks_container-item-image" />
        } else if (id == 41) {
            return <img src={potatoNFT_icon} alt="" className="tasks_container-item-image" />
        } else if (id == 45) {
            return <img src={SenerCrypto_icon} alt="" className="tasks_container-item-image" />
        } else if (id == 46) {
            return <img src={DoomerStory_icon} alt="" className="tasks_container-item-image" />
        } else if (id == 47) {
            return <img src={Tapula_icon} alt="" className="tasks_container-item-image" />
        } else if (id == 48) {
            return <img src={PandaBear_icon} alt="" className="tasks_container-item-image" />
        } else if (id == 49) {
            return <img src={Bull_mascot_icon} alt="" className="tasks_container-item-image" />
        } else if (id == 50) {
            return <img src={Gemsee_icon} alt="" className="tasks_container-item-image" />
        } else if (id == 52) {
            return <img src={Task52_icon} alt="" className="tasks_container-item-image" />
        } else {
            return <img src={clickerTheme == null ? telegramIcon : clickerTheme == 'dark' ? telegramDarkIcon : telegramLightIcon} alt="" className="tasks_container-item-image" />
        }
    }

    if (!isLoaded) return <Loader />;

    return (
        <div className="App">
            <div className="tasks_container">
                <a href={"https://t.me/share/url?url=https://t.me/pixie_project_bot?start=" + userId + "&text=" + shareText} className="referrals_task">
                    <img src={clickerTheme == null ? present_image : clickerTheme == 'dark' ? present_image_dark : present_image_light} alt="" />
                    <div className="referrals_task-text">
                        <span className="referrals_task-text-name">{t('Invite friends task')}</span>
                        <span className="referrals_task-text-undername">{t('Invite your friends and get both 20k coins each. Or 50k each for a friend who has Telegram premium')}</span>
                    </div>
                </a>
                {
                    viewPopup && currentTask &&
                    <div className="offline_profit_container">
                        <div className="offline_profit_container-content tasks">
                            <button className="offline_profit_container-content-button-close" onClick={() => { closePopupTasks() }}>✕</button>
                            <span className="offline_profit_container-content-text">{translatedName(currentTask)}</span>
                            <span className="offline_profit_container-content-value">
                                <img src={coinImage} alt="" />
                                {currentTask?.coins?.toLocaleString()}
                            </span>

                            {
                                [10, 11, 12].includes(currentTask.id) &&
                                <div className="tasks-invite-friends-progress-bar-container">
                                    <> ({currentTask.friends_invited === null ? 0 : currentTask.friends_invited} / {currentTask.friends_invite})</>
                                    <div className="tasks-invite-friends-progress-bar">
                                        <div className="tasks-invite-friends-progress-bar-value" style={{
                                            width: (((currentTask.friends_invited === null ? 0 : currentTask.friends_invited) / (currentTask.friends_invite ?? 0) * 100) > 100 ? 100 : ((currentTask.friends_invited === null ? 0 : currentTask.friends_invited) / (currentTask.friends_invite ?? 0) * 100)) + "%"
                                        }}>
                                        </div>
                                    </div>
                                </div>
                            }

                            <div className="popup_tasks_buttons-bottom">
                                {
                                    taskNotCompleted &&
                                    <span className="popup_tasks_buttons-bottom-text">{t('Task not completed')}!</span>
                                }
                                <div className="popup_tasks_buttons">
                                    {
                                        currentTask.id !== 15 &&
                                        <>
                                            {[10, 11, 12].includes(currentTask.id) ?
                                                <a className="popup_tasks_buttons-button" href={"https://t.me/share/url?url=https://t.me/pixie_project_bot?start=" + userId + "&text=" + shareText}>{t('Invite')}</a>
                                                :
                                                <button className="popup_tasks_buttons-button" onClick={() => { locateToLink(currentTask.link) }}>{t('Subscribe')}</button>
                                            }
                                        </>
                                    }
                                    {
                                        currentTask.id === 15
                                            ?
                                            <button
                                                className="popup_tasks_buttons-button"
                                                onClick={() => { checkRules(currentTask) }}
                                            >
                                                {t('Get')}
                                            </button>
                                            :
                                            <button
                                                className="popup_tasks_buttons-button second"
                                                onClick={() => { checkRules(currentTask) }}
                                            >
                                                {t('Check')}
                                            </button>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="offline_profit_container-overlay" onClick={() => { closePopupTasks() }}></div>
                    </div>
                }
                <div className="codes-form">
                    <input type="text" value={codeValue} onChange={(e) => { setCodeValue(e.target.value) }} className="codes-form-input" placeholder={t('send code')} />
                    <button className="codes-form-button" onClick={checkCode}>{t('send')}</button>
                </div>
                {
                    codeErrorText !== "" &&
                    <span className="under-codes-text error">{codeErrorText}</span>
                }
                {
                    codeSuccessText !== "" &&
                    <span className="under-codes-text success">{codeSuccessText}</span>
                }
                {
                    codeErrorText === "" && codeSuccessText === "" &&
                    <span className="under-codes-text">{t('Codes found')} {codesFound}</span>
                }
                {
                    tasksPartners.length > 0 &&
                    <>
                        <h1 className="tasks_container-name">{t('Partner tasks')}</h1>
                        <div className="tasks_container-column">
                            {tasksPartners.map((task, index) => (
                                <button className={"tasks_container-item " + (
                                    ([10, 11, 12].includes(task.id) ? (task.was_completed ? 'disabled' : '') : (task.task_id ? 'disabled' : ''))
                                )} onClick={() => { taskMore(task) }} key={index}>

                                    {taskImage(task.id)}
                                    <div className="tasks_container-item-text">
                                        <span className="tasks_container-item-text-name">
                                            {translatedName(task)}
                                            {[10, 11, 12].includes(task.id) ? <> ({task.friends_invited === null ? 0 : task.friends_invited} / {task.friends_invite})</> : ''}
                                        </span>
                                        <span className="tasks_container-item-text-value">+{task.coins.toLocaleString('ru')}</span>
                                    </div>
                                    <img src={iconArrow} alt="" className="tasks_container-item-icon-row" />
                                </button>
                            ))}
                        </div>
                    </>
                }
                <h1 className="tasks_container-name margin-top-10">{t('Tasks')}</h1>
                <div className="tasks_container-column">
                    {
                        tasksProject.map((task, index) => (
                            <button key={index} className={"tasks_container-item " + (
                                ([10, 11, 12].includes(task.id) ? (task.was_completed ? 'disabled' : '') : (task.task_id ? 'disabled' : ''))
                            )} onClick={() => { taskMore(task) }}>
                                {taskImage(task.id)}
                                <div className="tasks_container-item-text">
                                    <span className="tasks_container-item-text-name">
                                        {translatedName(task)}
                                        {[10, 11, 12].includes(task.id) ? <> ({task.friends_invited === null ? 0 : task.friends_invited} / {task.friends_invite})</> : ''}
                                    </span>
                                    <span className="tasks_container-item-text-value">+{task.coins.toLocaleString('ru')}</span>
                                </div>
                                <img src={iconArrow} alt="" className="tasks_container-item-icon-row" />
                            </button>
                        ))
                    }
                </div>
            </div>
        </div>
    );
}
