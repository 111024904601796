export class FillingGlassGameHistoryPlayer {
    constructor(
        public last_name: string | null,
        public first_name: string | null,
        public sum: number,
        public telegram_user_id: number | null,
    ) { }
}

export function mapToFillingGlassGameHistoryPlayer(data: any) {
    return new FillingGlassGameHistoryPlayer(
        data.last_name,
        data.first_name,
        data.sum,
        data.telegram_user_id
    )
}