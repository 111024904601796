import { mapToUser, User } from "../User/user";
import { Award, mapToAward } from "./award";
import { Family, mapToFamily } from "./family";

export class FamilyProvider {

    public static async create(file: File, name: string, description: string, token: string): Promise<any> {
        const formData = new FormData();
        formData.append('image', file);
        formData.append('name', name);
        formData.append('description', description);

        const response = await fetch(`${process.env.REACT_APP_API_URL}/v2/family/create`, {
            method: 'POST',
            headers: {
                'auth-api-token': token
            },
            body: formData,
        }).then(response => response.json())
            .then(response => response)

        return response;
    }

    public static async getList(userId: number): Promise<{ families: Family[], awards: Award[] }> {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/v3/family/list/${userId}`)
            .then(response => response.json())
            .then(response => response);

        const families = (response.families as any[]).map(mapToFamily);
        const awards = (response.awards as any[]).map(mapToAward);

        return {
            families: families,
            awards: awards
        };
    }

    public static async getUsers(familyId: number): Promise<User[]> {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/family/users/${familyId}`)
            .then(response => response.json())
            .then(response => response);

        return (response.users as any[]).map(mapToUser);
    }

    public static async edit(file: File, name: string, description: string, token: string): Promise<any> {
        const formData = new FormData();
        formData.append('image', file!);
        formData.append('name', name);
        formData.append('description', description);

        const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/family/edit`, {
            method: 'POST',
            headers: {
                'auth-api-token': token!
            },
            body: formData,
        }).then(response => response.json())
            .then(response => response)

        return response;
    }

    public static async leave(familyId: any, token: string): Promise<any> {
        let data = {
            "family_id": familyId
        };

        const response = fetch(`${process.env.REACT_APP_API_URL}/v2/family/leave`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'auth-api-token': token!
            },
            body: JSON.stringify(data)
        }).then(response => response.json())
            .then(response => response)

        return response;
    }

    public static async join(familyId: any, userId: number): Promise<any> {
        let data = {
            "family_id": familyId,
            "user_id": userId
        };

        const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/family/join`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
            body: JSON.stringify(data)
        }).then(response => response.json())
            .then(response => response)

        return response;
    }


    public static async getAward(awardUuid: string, token: string) {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/v3/family/get/award/${awardUuid}`, {
            method: "POST",
            headers: {
                'auth-api-token': token!
            },
        }).then(response => response.json())
            .then(response => response);

        return response;
    }
}