export class Category {
    constructor(
        public id: number,
        public name_en: string,
        public name_ru: string,
        public name_uk: string,
    ) { }
}

export function mapToCategory(data: any) {
    return new Category(
        data.id,
        data.name_en,
        data.name_ru,
        data.name_uk
    )
}