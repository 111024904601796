import ApexCharts from 'apexcharts';
import ReactApexChart from 'react-apexcharts';
import './fundsAllocationStyle.scss';
import { useTheme } from '../../../Context/Predict/themeContext';
import UsdtIcon from '../../../Resources/Predict/icons/USDT.svg'
import PixieIcon from '../../../Resources/Predict/icons/pixieLight.svg'
import TonIcon from '../../../Resources/Predict/icons/ton.svg'
import { useContext, useEffect, useState } from 'react';
import PLoader from '../../../Components/Predict/loader/Loader';
import { PredictProvider } from '../../../Domain/Predict/predictProdiver';
import GameContext from '../../../Context/GameContext';
import { Fund } from '../../../Domain/Predict/fund';
import { useTranslation } from 'react-i18next';
import { ProfileNftsBlock } from '../profile/nfts';

interface Statistic {
    percent: number;
    description: string;
    color: string;
}

interface ChartData {
    series: number[];
    options: ApexCharts.ApexOptions;
}

enum FundType {
    ton = 1,
    pix = 2,
    usdt = 3,
    nft = 4
}

export function FundsAllocationPage() {
    const { theme } = useTheme();
    const { token } = useContext(GameContext);

    const [funds, setFunds] = useState<Fund[]>([])
    const [selectedFund, setSelectedFund] = useState<FundType>(FundType.pix);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    useEffect(() => {
        updateFund();
    }, [])

    const { i18n } = useTranslation();

    async function updateFund() {
        setIsLoaded(false);

        const fundsResult = await PredictProvider.getFunds(token!);
        setFunds(fundsResult);

        setIsLoaded(true);
    }

    const statistics: Statistic[] = [
        { percent: 5, description: "Commission to the licence holders", color: theme == 'light' ? '#6cec9f' : '#BB69F5' },
        { percent: 25, description: "Commission to the NFT house owners", color: theme == 'light' ? '#d7ff65' : '#6E65DC' },
        { percent: 35, description: "Commission to the NFT landlords", color: theme == 'light' ? '#69ea69' : '#7A1AAB' },
        { percent: 45, description: "Staking fund", color: theme == 'light' ? '#addd8f' : '#8F86FF' },
        { percent: 15, description: "Charity fund", color: theme == 'light' ? '#d7ff65' : '#DC6EF2' },
        { percent: 70, description: "Commission of the game creator", color: theme == 'light' ? '#B5E627' : '#9567E9' }
    ];

    const chartData: ChartData = {
        series: statistics.map(stat => stat.percent),
        options: {
            chart: {
                type: 'radialBar',
            },
            plotOptions: {
                radialBar: {
                    hollow: {
                        size: '10%'
                    },
                    track: {
                        background: theme == 'light' ? "#585858" : "#382f5a",
                        strokeWidth: '90px',
                    },
                    dataLabels: {
                        name: {
                            show: false,
                        },
                        value: {
                            show: false,
                        },
                    },
                },
            },
            labels: statistics.map(stat => stat.description),
            stroke: {
                lineCap: "round",
            },
            fill: {
                type: 'solid',
                colors: statistics.map(stat => stat.color),
            }
        },
    };

    function getFundName(fund: Fund): string {
        if (i18n.language === 'ru') {
            return fund.name_ru ?? fund.name_en ?? "";
        } else {
            return fund.name_en ?? "";
        }
    }

    function getBalance(fund: Fund) {
        switch (selectedFund) {
            case FundType.pix: return +fund.balance_pixie_clicker;
            case FundType.ton: return +fund.balance_ton;
            case FundType.usdt: return +fund.balance_usdt;
            case FundType.nft: throw new Error("");
        }
    }

    function parseBigNumber(number: number) {
        if (number >= 10_000 && number < 1_000_000) {
            return `${(+Math.round(number / 1000)).toLocaleString("en")}k`
        } else if (number >= 1_000_000) {
            return `${(+Math.round(number / 1_000_000)).toLocaleString("en")}kk`
        } else {
            return `${(+Math.round(number)).toLocaleString("en")}`
        }
    }

    return (
        <div className="fundsAllocationContainer">
            <div className="fundsAllocationContainer-navigation">
                <div className={`fundsAllocationContainer-navigation-item ${selectedFund == FundType.pix ? 'active' : ""}`} onClick={() => setSelectedFund(FundType.pix)}>
                    PIX
                </div>
                <div className={`fundsAllocationContainer-navigation-item ${selectedFund == FundType.usdt ? 'active' : ""}`} onClick={() => setSelectedFund(FundType.usdt)}>
                    USDT
                </div>
                <div className={`fundsAllocationContainer-navigation-item ${selectedFund == FundType.ton ? 'active' : ""}`} onClick={() => setSelectedFund(FundType.ton)}>
                    TON
                </div>
                <div className={`fundsAllocationContainer-navigation-item ${selectedFund == FundType.nft ? 'active' : ""}`} onClick={() => setSelectedFund(FundType.nft)}>
                    NFT
                </div>
            </div>


            {selectedFund != FundType.nft
                ?
                <>
                    <div className="fundsAllocationContainer-chart">
                        <ReactApexChart
                            options={chartData.options}
                            series={chartData.series}
                            type="radialBar"
                            height={350}
                        />
                    </div>

                    <div className="fundsAllocationContainer_chartValues">
                        {isLoaded
                            ? funds.map((fund, index) =>
                                <div key={index} className="fundsAllocationContainer_chartValue">
                                    <div
                                        style={{
                                            width: '6px',
                                            height: '6px',
                                            borderRadius: '50%',
                                            marginRight: '8px',
                                            backgroundColor: theme == 'light' ? '#6cec9f' : '#BB69F5',
                                        }}
                                    />
                                    <div className="fundsAllocationContainer_chartValue_description">
                                        {getFundName(fund)}
                                    </div>
                                    <div className="fundsAllocationContainer_chartValue_persent">
                                        {fund.percents}%
                                    </div>
                                    <div className="fundsAllocationContainer_chartValue_description">
                                        {parseBigNumber(getBalance(fund))}
                                    </div>
                                </div>
                            )
                            : <PLoader />
                        }
                    </div>
                </>
                : <ProfileNftsBlock />
            }
        </div>
    )
}
