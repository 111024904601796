import { useEffect, useState } from 'react';
import style from './loaderStyle.module.scss';

export default function PLoader() {
    const [loadingText, setLoadingText] = useState('Loading');

    useEffect(() => {
        let counterInterval = 0;

        let interval = setInterval(() => {
            counterInterval++;

            if (counterInterval === 1) {
                setLoadingText("Loading.")
            } else if (counterInterval === 2) {
                setLoadingText("Loading..")
            } else if (counterInterval === 3) {
                setLoadingText("Loading...")
            } else {
                setLoadingText("Loading")
                counterInterval = 0;
            }
        }, 500);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className={style.loader_container}>
            <div className={style.loader}></div>
            <span className={style.loader_text}>{loadingText}</span>
        </div>
    );
};