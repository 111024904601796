import React from 'react'
import style from './actionButtonStyle.module.scss'
import { useTheme } from '../../../Context/Predict/themeContext';
import FireIcon from '../../../Resources/Predict/icons/fire.svg';

interface IProps {
    title: string;
    subTitle?: string | null;
    onClick?: () => void;
    flat?: boolean;
    disabled?: boolean;
    selected?: boolean;
    withoutIcon?: boolean;
    size?: "normal" | "small";
    isHot?: boolean;
}

export function UpButton(props: IProps) {
    const size = props.size ?? "normal";
    const { theme } = useTheme();

    function handleClick() {
        if (props.disabled == true || !props.onClick) return;

        props.onClick();
    }

    return (
        <div className={`${style.container} ${style.green} ${props.flat ? style.flat : ""} ${props.disabled == true ? style.disabled : ''} ${props.selected == true ? style.selected : ''}  ${size == 'small' ? style.small : ''}`} onClick={handleClick}>
            <span className={`${style.title} ${style.green}`}>{props.title}</span>
            {props.subTitle && <span className={`${style.subTitle} ${style.green}`}>{props.subTitle}</span>}
            {
                props.withoutIcon != true &&
                <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.63397 1.5C6.01887 0.833333 6.98113 0.833334 7.36603 1.5L10.8301 7.5C11.215 8.16667 10.7339 9 9.9641 9H3.0359C2.2661 9 1.78497 8.16667 2.16987 7.5L5.63397 1.5Z" fill={`${props.disabled ? "#cacaca" : theme == 'dark' || props.flat ? '#25b35e' : "#212121"}`} />
                </svg>
            }
            {props.isHot == true && <img src={FireIcon} />}
        </div>
    )
}
