import React, { PropsWithChildren } from 'react'
import styles from './modalStyule.module.scss'

interface IProps {
    isOpen: boolean;
    onClose: () => void;
}

export default function Modal(props: IProps & PropsWithChildren) {
    if (!props.isOpen) return null;

    return (
        <div className={styles.modalOverlay} onClick={props.onClose}>
            <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
                {props.children}
            </div>
        </div>
    )
}
